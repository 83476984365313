<div class="classcontainer">
  <div class="modal-header">
    <h4 class="modal-title">Global Topics</h4>
  </div>
​
  <div class="modal-body px-0">
    <table mat-table [dataSource]="dataSource">
​
​
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </td>
      </ng-container>
​
      <!-- Position Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Topic Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>
​
      <!-- Name Column -->
      <ng-container matColumnDef="board">
        <th mat-header-cell *matHeaderCellDef> Board </th>
        <td mat-cell *matCellDef="let element"> {{element.board_id.name}} </td>
      </ng-container>
​
      <!-- Weight Column -->
      <ng-container matColumnDef="class">
        <th mat-header-cell *matHeaderCellDef> Class </th>
        <td mat-cell *matCellDef="let element"> {{element.class_id.name}} </td>
      </ng-container>
​
      <!-- Weight Column -->
      <ng-container matColumnDef="syllabus">
        <th mat-header-cell *matHeaderCellDef> Syllubus </th>
        <td mat-cell *matCellDef="let element"> {{element.syllabus_id.name}} </td>
      </ng-container>
​
      <!-- Weight Column -->
      <ng-container matColumnDef="subject">
        <th mat-header-cell *matHeaderCellDef> Subject </th>
        <td mat-cell *matCellDef="let element"> {{element.subject_id.name}} </td>
      </ng-container>
​
      <!-- Weight Column -->
      <ng-container matColumnDef="chapter">
        <th mat-header-cell *matHeaderCellDef> Chapter </th>
        <td mat-cell *matCellDef="let element"> {{element.chapter_id.name}} </td>
      </ng-container>
​
      <!-- Weight Column -->
      <ng-container matColumnDef="topic">
        <th mat-header-cell *matHeaderCellDef> Topic </th>
        <td mat-cell *matCellDef="let element"> {{element.topic_id.name}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
      </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10]"></mat-paginator>
  </div>
  <div class="modal-body px-0">
    <div class="col-md-12 import-btn ">
      <button class="btn btn-primary" (click)="submit()">Submit</button>
    </div>
  </div>
</div>
