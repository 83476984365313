<div>
  <h3>Activate Billing</h3>
  <div class="date-selector">
    <mat-form-field appearance="fill">
      <mat-label>Choose Start date</mat-label>
      <input matInput [matDatepicker]="picker" [(ngModel)]="startDate" (dateChange)="getPaymentStats()">
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Choose End date</mat-label>
      <input matInput [matDatepicker]="picker2" [(ngModel)]="endDate" (dateChange)="getPaymentStats()">
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="stats">
    <mat-card class="stats-card">
      <mat-spinner *ngIf="loading"></mat-spinner>
      <mat-card-title *ngIf="!loading">{{paymentStats.paid_schools}}</mat-card-title>
      <mat-card-subtitle *ngIf="!loading">Paid Schools</mat-card-subtitle>
    </mat-card>
    <mat-card class="stats-card">
      <mat-spinner *ngIf="loading"></mat-spinner>
      <mat-card-title *ngIf="!loading">₹ {{paymentStats.received_amount | number: '1.0-0'}}</mat-card-title>
      <mat-card-subtitle *ngIf="!loading">Received Amount</mat-card-subtitle>
    </mat-card>
    <mat-card class="stats-card">
      <mat-spinner *ngIf="loading"></mat-spinner>
      <mat-card-title *ngIf="!loading">{{paymentStats.unpaid_schools}}</mat-card-title>
      <mat-card-subtitle *ngIf="!loading">Unpaid Schools</mat-card-subtitle>
    </mat-card>
    <mat-card class="stats-card">
      <mat-spinner *ngIf="loading"></mat-spinner>
      <mat-card-title *ngIf="!loading">₹ {{paymentStats.pending_amount | number: '1.0-0'}}</mat-card-title>
      <mat-card-subtitle *ngIf="!loading">Pending Amount</mat-card-subtitle>
    </mat-card>
  </div>
  <mat-form-field appearance="standard" style="width: 100%;">
    <mat-label>Filter</mat-label>
    <input (keyup)="applyFilter($event)" matInput placeholder="Search columns" #input>
  </mat-form-field>
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" style="width: 100%;">

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.schoolName}} </td>
      </ng-container>

      <!-- Contact Column -->
      <ng-container matColumnDef="contact">
        <th mat-header-cell *matHeaderCellDef> Contact </th>
        <td mat-cell *matCellDef="let element"> {{element.contact_number}} </td>
      </ng-container>

      <!-- Code Column -->
      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef> Code </th>
        <td mat-cell *matCellDef="let element"> {{element.school_code}} </td>
      </ng-container>

      <!-- Billing Column -->
      <ng-container matColumnDef="onboarded">
        <th mat-header-cell *matHeaderCellDef> Onboarded Date </th>
        <td mat-cell *matCellDef="let element">
          <button mat-raised-button color="black" *ngIf="!element.onboard"
            (click)="onboardPopup(element.schoolName,element._id)">
            <mat-icon>date_range</mat-icon> Set Date
          </button>
          <button mat-raised-button color="black" *ngIf="element.onboard">
            <mat-icon>date_range</mat-icon> {{element.onboard}}
          </button>
        </td>
      </ng-container>

      <!-- View Bills Column -->
      <ng-container matColumnDef="view">
        <th mat-header-cell *matHeaderCellDef> View Bills </th>
        <td mat-cell *matCellDef="let element"> <button mat-raised-button color="primary"
            (click)="getOrders(element._id)">
            <mat-icon>visibility</mat-icon> View Bills
          </button> </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element">
          {{ element.payment.orders[0] ? (element.payment.status ? 'Pending': 'Paid') : 'No Status' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="activate">
        <th mat-header-cell *matHeaderCellDef> Activate Billing </th>
        <td mat-cell *matCellDef="let element;let i = index">
          <button mat-raised-button style="background-color: #11823b;color:white"
            [hidden]="!element.payment.activeStatus">
            <mat-icon>check_circle</mat-icon> Activated
          </button>
          <button mat-raised-button color="primary"
            (click)="open(element.schoolName,element._id,element.payment.activeStatus)"
            [hidden]="element.payment.activeStatus">
            <mat-icon>event_available</mat-icon> Activate
          </button>
        </td>
      </ng-container>



      <tr mat-header-row *matHeaderRowDef=" displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>

</div>