<div class="classcontainer">
  <div class="modal-header">
    <h4 class="modal-title">Update Principle</h4>
  </div>
  <div class="modal-body px-0" *ngIf="principleForm.value">
    <div style="overflow-y: hidden; height: calc(100vh - 15rem);">
      <div class="px-2" style="overflow-y: auto; height: 100%;">
        <form [formGroup]="principleForm">
          <!-- Name -->
          <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput placeholder="Name" formControlName="name" class="form-control" #firstname="matInput"
              required>
            <mat-error *ngIf="principleForm.get('name').hasError('required')">Principal name is required </mat-error>
            <mat-error *ngIf="principleForm.get('name').hasError('maxlength')">You can only enter maximum
              of 50 character </mat-error>
          </mat-form-field>
          <div class="row">
            <div class="col-md-4 col-sm-4 col-xs-12">
              <!-- Contact -->
              <mat-form-field>
                <mat-label>Contact Number*</mat-label>
                <input type="number" matInput placeholder="Contact Number" formControlName="mobile" readonly>

              </mat-form-field>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
              <!-- Password -->
              <mat-form-field>
                <mat-label>Password*</mat-label>
                <input type="text" matInput placeholder="Password" formControlName="password" class="form-control">
                <mat-error *ngIf="principleForm.get('password').hasError('required')"> password is required </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
              <mat-form-field>
                <mat-label>Branch*</mat-label>
                <mat-select placeholder="Branch" formControlName="branch_id">
                  <mat-option *ngFor="let branch of branches" [value]="branch.Name">
                    {{branch.Name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="principleForm.get('branch_id').hasError('required')"> please select the branch
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 col-sm-4 col-xs-12">
              <!-- Gender -->
              <mat-radio-group formControlName="gender" color="primary">
                <mat-label>Gender*</mat-label>
                <mat-radio-button class="example-radio-button" *ngFor="let gend of genders" [value]="gend">{{gend}}
                </mat-radio-button>
                <mat-error *ngIf="principleForm.get('gender').hasError('required')"> gender is required </mat-error>
              </mat-radio-group>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
              <!-- DOB -->
              <mat-form-field>
                <mat-label>Date of Birth (Please select from calender)</mat-label>
                <input matInput [matDatepicker]="picker2" formControlName="dob">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
              <!-- Email -->
              <mat-form-field>
                <mat-label>Email</mat-label>
                <input type="email" matInput placeholder="Email" formControlName="email">
                <mat-error *ngIf="principleForm.get('email').hasError('pattern')"> Invalid email format
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 col-sm-4 col-xs-12">

              <mat-form-field>
                <!-- <mat-label>Primary Class</mat-label> -->
                <mat-select (selectionChange)="primaryClassChange($event)" name="primaryClass"
                  placeholder="Primary Class" formControlName="primary_class">
                  <mat-option [value]="">Primary Class</mat-option>
                  <mat-option *ngFor="let primary_class of class" [value]="primary_class.classId">
                    {{primary_class.className}}
                  </mat-option>
                </mat-select>

              </mat-form-field>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
              <!-- Section/Division -->
              <mat-form-field>
                <!-- <mat-label>Section/Division</mat-label> -->
                <mat-select placeholder="Section/Division" formControlName="primary_section">
                  <mat-option [value]="">Section/Division</mat-option>
                  <mat-option *ngFor="let section of sections" [value]="section._id">{{section.name}}</mat-option>
                </mat-select>
                <!-- <mat-error *ngIf="studentForm.get('section').hasError('required')">required </mat-error> -->
              </mat-form-field>
            </div>

            <!-- <div class="col-md-4 col-sm-4 col-xs-12">
              <mat-form-field>
                <mat-label>Secondary Classes</mat-label>
                <mat-select name="secondaryClass" placeholder="" formControlName="secondary_class" multiple>
                  <mat-option *ngFor="let class of class" [value]="class.classId">
                    {{class.className}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div> -->

          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="import-btn" style="float: left;">
                <button [disabled]="secondaryClassformArray().length>4" class="btn btn-primary"
                  (click)="addSecondaryClass()">Add Secondary Class</button>
              </div>
            </div>
          </div>
          <ng-container *ngIf="principleForm.get('secondaryClass')">
            <div formArrayName="secondaryClass"
              *ngFor="let sclass of principleForm.get('secondaryClass')['controls'] as FormArray; let i=index">
              <div [formGroupName]="i">
                <div class="row">
                  <div class="col-md-5">
                    <mat-form-field>
                      <mat-label>Secondary Class</mat-label>
                      <mat-select (selectionChange)="secondaryClassChange($event,i)" name="secondClasses"
                        placeholder="Secondary Class" formControlName="secondClasses">
                        <mat-option [value]="">Secondary Class</mat-option>
                        <mat-option *ngFor="let class of class" [value]="class.classId">
                          {{class.className}}
                        </mat-option>
                      </mat-select>
                      <!-- <mat-error *ngIf="teacherForm.get('secondaryClass').hasError('required')"> required </mat-error> -->
                    </mat-form-field>
                  </div>
                  <div class="col-md-5">
                    <mat-form-field>
                      <mat-select *ngIf="i==0" placeholder="Section/Division" name="section" id="'section'+i"
                        formControlName="section">
                        <mat-option [value]="">Section/Division</mat-option>
                        <mat-option
                          [disabled]="this.principleForm.controls.secondaryClass.value[i].secondClasses==undefined || this.principleForm.controls.secondaryClass.value[i].secondClasses==null || this.principleForm.controls.secondaryClass.value[i].secondClasses=='' "
                          *ngFor="let section of secondarySection1" [value]="section._id">{{section.name}}</mat-option>
                      </mat-select>
                      <mat-select *ngIf="i==1" placeholder="Section/Division" name="section" id="'section'+i"
                        formControlName="section">
                        <mat-option [value]="">Section/Division</mat-option>
                        <mat-option
                          [disabled]="this.principleForm.controls.secondaryClass.value[i].secondClasses==undefined || this.principleForm.controls.secondaryClass.value[i].secondClasses==null || this.principleForm.controls.secondaryClass.value[i].secondClasses=='' "
                          *ngFor="let section of secondarySection2" [value]="section._id">{{section.name}}</mat-option>
                      </mat-select>
                      <mat-select *ngIf="i==2" placeholder="Section/Division" name="section" id="'section'+i"
                        formControlName="section">
                        <mat-option [value]="">Section/Division</mat-option>
                        <mat-option
                          [disabled]="this.principleForm.controls.secondaryClass.value[i].secondClasses==undefined || this.principleForm.controls.secondaryClass.value[i].secondClasses==null || this.principleForm.controls.secondaryClass.value[i].secondClasses=='' "
                          *ngFor="let section of secondarySection3" [value]="section._id">{{section.name}}</mat-option>
                      </mat-select>
                      <mat-select *ngIf="i==3" placeholder="Section/Division" name="section" id="'section'+i"
                        formControlName="section">
                        <mat-option [value]="">Section/Division</mat-option>
                        <mat-option
                          [disabled]="this.principleForm.controls.secondaryClass.value[i].secondClasses==undefined || this.principleForm.controls.secondaryClass.value[i].secondClasses==null || this.principleForm.controls.secondaryClass.value[i].secondClasses=='' "
                          *ngFor="let section of secondarySection4" [value]="section._id">{{section.name}}</mat-option>
                      </mat-select>
                      <mat-select *ngIf="i==4" placeholder="Section/Division" name="section" id="'section'+i"
                        formControlName="section">
                        <mat-option [value]="">Section/Division</mat-option>
                        <mat-option
                          [disabled]="this.principleForm.controls.secondaryClass.value[i].secondClasses==undefined || this.principleForm.controls.secondaryClass.value[i].secondClasses==null || this.principleForm.controls.secondaryClass.value[i].secondClasses=='' "
                          *ngFor="let section of secondarySection5" [value]="section._id">{{section.name}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-md-2" *ngIf="principleForm.get('secondaryClass').length>0">
                    <button class="btn btn-danger btn-sm" (click)="removeSecodaryClass(i)">X</button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- Address -->
          <mat-form-field appearance="fill">
            <mat-label>Address</mat-label>
            <textarea matInput placeholder="Address" formControlName="address"></textarea>
            <mat-error *ngIf="principleForm.get('address').hasError('maxlength')">You can only enter maximum
              of 150 character </mat-error>
          </mat-form-field>
          <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-12">
              <!-- Country -->
              <mat-form-field>
                <mat-label>Select Country</mat-label>
                <mat-select placeholder="Country" formControlName="country">
                  <mat-option *ngFor="let country of countries" [value]="country._id">
                    {{country.country_name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

            </div>
            <div class="col-md-3 col-sm-3 col-xs-12">
              <!-- State -->
              <mat-form-field>
                <mat-label>Select State</mat-label>
                <mat-select placeholder="State" formControlName="state">
                  <mat-option *ngFor="let state of states" [value]="state._id">
                    {{state.state_name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-12">
              <!-- City -->
              <mat-form-field>
                <mat-label>Select City</mat-label>
                <mat-select placeholder="City" formControlName="city">
                  <mat-option *ngFor="let city of cities" [value]="city._id">
                    {{city.city_name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-12">
              <mat-form-field>
                <mat-label>Pincode</mat-label>
                <input matInput type="tel" placeholder="pincode" formControlName="pincode" class="form-control">
                <mat-error *ngIf="principleForm.get('pincode').hasError('maxlength')">You can only enter maximum
                  of 10 number </mat-error>

                <mat-error *ngIf="principleForm.get('pincode').hasError('pattern')"> pincode can only have numbers
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-12">
              <!-- Aadhar card no -->
              <mat-form-field>
                <mat-label>Aadhaar Card no</mat-label>
                <input type="tel" matInput placeholder="Aadhaar Card no" formControlName="aadhar_card"
                  class="form-control">
                <mat-error *ngIf="principleForm.get('aadhar_card').hasError('maxlength')">you have reached maximum limit
                  of
                  characters </mat-error>
                <mat-error *ngIf="principleForm.get('aadhar_card').hasError('pattern')"> aadhaarNo can only have numbers
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-12">
              <!-- Blood group -->
              <mat-form-field>
                <mat-label>Blood Group</mat-label>
                <input matInput placeholder="Blood Group" formControlName="blood_gr" class="form-control">
              </mat-form-field>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-12">
              <!-- Mother Tongue -->
              <mat-form-field>
                <mat-label>Mother Tongue</mat-label>
                <input matInput placeholder="Mother Tongue" formControlName="mother_tounge" class="form-control">
              </mat-form-field>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-12">
              <!-- Marital status -->
              <mat-form-field>
                <mat-label>Marital status</mat-label>
                <mat-select placeholder="Marital status" formControlName="marital_status">
                  <mat-option *ngFor="let mode of maritalStatuses" [value]="mode">
                    {{mode}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 col-sm-4 col-xs-12">
              <!-- Religion -->
              <mat-form-field>
                <mat-label>Religion</mat-label>
                <input matInput placeholder="Religion" formControlName="religion" class="form-control">
              </mat-form-field>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
              <!-- Cast -->
              <mat-form-field>
                <mat-label>Caste</mat-label>
                <input matInput placeholder="Cast" formControlName="caste" class="form-control">
              </mat-form-field>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12"></div>
          </div>
          <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-12">
              <!-- Qualification  -->
              <mat-form-field>
                <mat-label>Qualification</mat-label>
                <input matInput placeholder="Qualification" formControlName="qualification" class="form-control">
              </mat-form-field>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-12">
              <!-- Teaching levels  -->
              <mat-form-field>
                <mat-label>Teaching levels</mat-label>
                <mat-select placeholder="Teaching levels" formControlName="level">
                  <mat-option *ngFor="let mode of teachingLevels" [value]="mode">
                    {{mode}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-12">
              <!-- Teaching experience -->
              <mat-form-field>
                <mat-label>Teaching experience</mat-label>
                <input matInput placeholder="Teaching experience" formControlName="experience" class="form-control">
              </mat-form-field>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-12">
              <label>Curriculum Vitae</label>
              <input #cvInput type="file" (change)="onFileInput($event,'cv')">
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-12">
              <!-- Leadership Experience -->
              <mat-form-field>
                <mat-label>Leadership Experience</mat-label>
                <input matInput placeholder="Leadership Experience" formControlName="leaderShip_Exp"
                  class="form-control">
              </mat-form-field>
            </div>
          </div>
          <!-- Sub Form Educational details  -->
          <hr>
          <h1>Educational details</h1>
          <h3>10th Details</h3>
          <div formGroupName="ten_details">
            <div class="row">
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- School -->
                <mat-form-field>
                  <mat-label>School</mat-label>
                  <input matInput placeholder="School Name" formControlName="school" class="form-control">
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- Board -->
                <mat-form-field>
                  <mat-label>Board</mat-label>
                  <input matInput placeholder="Board" formControlName="Board" class="form-control">
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- Percentage -->
                <mat-form-field>
                  <mat-label>Percentage</mat-label>
                  <input type="number" matInput placeholder="Percentage" formControlName="percentage"
                    class="form-control">
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12">
                <mat-form-field>
                  <mat-label>Year of Passing</mat-label>
                  <mat-select placeholder="Year of Passing" formControlName="year_of_passing">
                    <mat-option *ngFor="let year of yearOfPassing" [value]="year">
                      {{year}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 col-sm-3 col-xs-12">
                <label>Upload 10<sup>th</sup> Document</label>
                <input #fileInput type="file" (change)="onFileInput($event,'10th')" />
              </div>
            </div>
          </div>
          <!-- Year of Passing -->
          <!-- Attach Documents -->
          <!-- <mat-form-field>
          <mat-label>Attach Documents</mat-label>
          <input type="file" matInput placeholder="Attach Documents" formControlName="tenthDoc" class="form-control" required>
      </mat-form-field> -->
          <hr>
          <h3>12th Details</h3>
          <div formGroupName="twelve_details">
            <div class="row">
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- School -->
                <mat-form-field>
                  <mat-label>School</mat-label>
                  <input matInput placeholder="School Name" formControlName="school" class="form-control">
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- Board -->
                <mat-form-field>
                  <mat-label>Board</mat-label>
                  <input matInput placeholder="Board" formControlName="Board" class="form-control">
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- Percentage -->
                <mat-form-field>
                  <mat-label>Percentage</mat-label>
                  <input type="number" matInput placeholder="Percentage" formControlName="percentage"
                    class="form-control">
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- Year of Passing -->
                <mat-form-field>
                  <mat-label>Year of Passing</mat-label>
                  <mat-select placeholder="Year of Passing" formControlName="year_of_passing">
                    <mat-option *ngFor="let year of yearOfPassing" [value]="year">
                      {{year}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 col-sm-3 col-xs-12">
                <label>Upload 12<sup>th</sup> Document</label>
                <input #fileInput type="file" (change)="onFileInput($event,'12th')" />
              </div>
            </div>
          </div>
          <!-- Attach Documents -->
          <!-- <mat-form-field>
          <mat-label>Attach Documents</mat-label>
          <input type="file" matInput placeholder="Attach Documents" formControlName="twelveDoc" class="form-control" required>
      </mat-form-field> -->
          <!-- <button (click)="fileInput.click()" class="btn btn-primary">
          <span>Attach 12th Document</span>
          <input #fileInput type="file" (change)="onFileInput($event,'12th')" style="display:none;" />
      </button> -->
          <hr>
          <h3>Graduation Details</h3>
          <div formGroupName="graduation_details">
            <div class="row">
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- School -->
                <mat-form-field>
                  <mat-label>School</mat-label>
                  <input matInput placeholder="School Name" formControlName="school" class="form-control">
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- Board -->
                <mat-form-field>
                  <mat-label>Board</mat-label>
                  <input matInput placeholder="Board" formControlName="Board" class="form-control">
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- Percentage -->
                <mat-form-field>
                  <mat-label>Percentage</mat-label>
                  <input type="number" matInput placeholder="Percentage" formControlName="percentage"
                    class="form-control">
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- Year of Passing -->
                <mat-form-field>
                  <mat-label>Year of Passing</mat-label>
                  <mat-select placeholder="Year of Passing" formControlName="year_of_passing">
                    <mat-option *ngFor="let year of yearOfPassing" [value]="year">
                      {{year}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 col-sm-3 col-xs-12">
                <label>Attach Documents</label>
                <input #fileInput type="file" (change)="onFileInput($event,'grad')" />
              </div>
            </div>
          </div>
          <!-- Attach Documents -->
          <!-- <mat-form-field>
          <mat-label>Attach Documents</mat-label>
          <input type="file" matInput placeholder="Attach Documents" formControlName="gradDoc" class="form-control" required>
      </mat-form-field> -->
          <hr>
          <h3>Masters Details</h3>
          <div formGroupName="masters_details">
            <div class="row">
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- School -->
                <mat-form-field>
                  <mat-label>School</mat-label>
                  <input matInput placeholder="School Name" formControlName="school" class="form-control">
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- Board -->
                <mat-form-field>
                  <mat-label>Board</mat-label>
                  <input matInput placeholder="Board" formControlName="Board" class="form-control">
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- Percentage -->
                <mat-form-field>
                  <mat-label>Percentage</mat-label>
                  <input type="number" matInput placeholder="Percentage" formControlName="percentage"
                    class="form-control">
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- Year of Passing -->
                <mat-form-field>
                  <mat-label>Year of Passing</mat-label>
                  <mat-select placeholder="Year of Passing" formControlName="year_of_passing">
                    <mat-option *ngFor="let year of yearOfPassing" [value]="year">
                      {{year}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 col-sm-3 col-xs-12">
                <label>Attach Document</label>
                <input #fileInput type="file" (change)="onFileInput($event,'master')" />
              </div>
            </div>
          </div>
          <!-- Attach Documents -->
          <!-- <mat-form-field>
          <mat-label>Attach Documents</mat-label>
          <input type="file" matInput placeholder="Attach Documents" formControlName="masterDoc" class="form-control" required>
      </mat-form-field> -->
          <!--  -->
          <hr>
          <h3>Other Degrees</h3>
          <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-12">
              <label>Other Degrees</label>
              <input #fileInput type="file" (change)="onFileInput($event,'otherDeg')" />
            </div>
            <div class="col-md-3 col-sm-3 col-xs-12">
              <label>Certifications</label>
              <input #fileInput type="file" (change)="onFileInput($event,'certi')" />
            </div>
            <div class="col-md-3 col-sm-3 col-xs-12">
              <label>Extra-Curricular achievements</label>
              <input #fileInput type="file" (change)="onFileInput($event,'extraCur')" />
            </div>
          </div>
          <!-- Other Degrees -->
          <!-- <mat-form-field>
          <mat-label>Other Degrees</mat-label>
          <input type="file" matInput placeholder="Other Degrees" formControlName="otherDegrees" class="form-control" required>
      </mat-form-field> -->
          <!-- Certifications -->
          <!-- <mat-form-field>
          <mat-label>Certifications</mat-label>
          <input type="file" matInput placeholder="Certifications" formControlName="certifications" class="form-control" required>
      </mat-form-field> -->
          <div>
          </div>
          <!-- Extra-Curricular achievements -->
          <!--  <mat-form-field>
          <mat-label>Extra-Curricular achievements</mat-label>
          <input type="file" matInput placeholder="Extra-Curricular achievements" formControlName="extraCurricularAchievements" class="form-control" required>
      </mat-form-field> -->
          <div>
          </div>
          <!-- Submit -->

        </form>
        <!-- 
<div class="form-group import-btn ">
  <button [disabled]="!principleForm.valid" class="btn btn-primary">update
    principle</button>
</div> -->

        <form #update="ngForm" (ngSubmit)="updatePrinciple()">

          <div class="form-group import-btn ">
            <button class="btn btn-primary" [disabled]="!principleForm.valid">Update principle</button>
            <input type="reset" id="reset" class="btn btn-primary" value="Reset" hidden>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>