<div *ngIf="!isOwner;else own;">
  <div class="row classContainer" autocomplete="off">
    <div class="row" style="width: 100%;">

      <div class="col-md-5 col-sm-5 col-xs-12">
        <div *ngIf="!isOwner;else myFlagTrue;">
          <h3 *ngIf="!editingClass">Add Class</h3>
          <h3 *ngIf="editingClass">Edit Class <strong>{{name}}</strong></h3>
          <form #update="ngForm" (ngSubmit)="addClass(update.value)">
            <div class="form-group">
              <label>Name*</label>
              <input type="text" name="name" id="" class="form-control" [(ngModel)]="name">
            </div>
            <div class="form-group">
              <label>Description</label>
              <!-- <textarea name="description" id="" cols="30" rows="10" class="form-control"
                [(ngModel)]="description"></textarea> -->
                <editor [init]="{base_url: '/tinymce', suffix: '.min'}" [ngModelOptions]="{standalone: true}"
                [(ngModel)]="description"></editor>
            </div>
            <div class="form-group import-btn ">
              <button class="btn btn-primary">{{btnTitle}}</button>
              <a href="javascript:void(0);" (click)="cancelEdit()" class="cancelLink" *ngIf="editingClass">Cancel</a>
              <input type="reset" id="reset" class="btn btn-primary" value="Reset" hidden>
            </div>
          </form>
        </div>
      </div>

      <div class="col-md-7 col-sm-7 col-xs-12 ">

        <ng-template #myFlagTrue>

          <!-- <div class="import-btn">
            <button class="btn btn-primary" (click)="import()">Import</button>
          </div> -->

        </ng-template>





        <mat-form-field>
          <mat-label>Filter</mat-label>
          <input matInput placeholder="Search" (keyup)="applyFilter($event)" #input>
        </mat-form-field>

        <div class="mat-elevation-z8">
          <table mat-table [dataSource]="dataSource" matSort>

            <!--  name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
              <td mat-cell *matCellDef="let row"> {{row.name}} </td>
            </ng-container>

            <!-- description Column -->
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
              <td mat-cell *matCellDef="let row" [innerHtml]="row.description"> </td>
            </ng-container>

            <!-- Author Column -->
            <!-- <ng-container matColumnDef="createdBy">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Author </th>
              <td mat-cell *matCellDef="let row"> {{row.createdBy}} </td>
            </ng-container> -->


            <ng-container matColumnDef="action">
              <div *ngIf="!isOwner;else myFlagTru;">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
                <td mat-cell *matCellDef="let row">
                  <button class="btn btn-primary" (click)="updateClass(row._id,row.name,row.description)">
                    <mat-icon aria-hidden="false" aria-label="Update">edit</mat-icon>
                  </button>
                  <button class="btn btn-primary" (click)="deleteClass(row)">
                    <mat-icon aria-hidden="false" aria-label="Delete">delete</mat-icon>
                  </button>
                </td>


              </div>

              <ng-template #myFlagTru>
                <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                <td mat-cell *matCellDef="let row">

                </td>
              </ng-template>



            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr>
          </table>
          <mat-paginator #MatPaginator [length]="dataSource.data.length" [pageSizeOptions]="[5, 10, 25, 100]">
          </mat-paginator>
        </div>



        <h3 *ngIf="!classesLoaded" style="text-align: center; margin-top: 40px;"><strong>Loading classes please
            wait</strong></h3>
      </div>
    </div>
  </div>
</div>


<ng-template #own>
  <div class="row classContainer" autocomplete="off">
    <div class="row" style="width: 100%;">



      <div class="col-md-12 col-sm-12 col-xs-12 ">



        <div class="import-btn">
          <button class="btn btn-primary" (click)="import()">Import</button>
        </div>

        <mat-form-field>
          <mat-label>Filter</mat-label>
          <input matInput placeholder="Search" (keyup)="applyFilter($event)" #input>
        </mat-form-field>

        <div class="mat-elevation-z8">
          <table mat-table [dataSource]="dataSource2" matSort>

            <!--  name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
              <td mat-cell *matCellDef="let row"> {{row.className}} </td>
            </ng-container>

            <!-- description Column -->
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
              <td mat-cell *matCellDef="let row"> {{row.description}} </td>
            </ng-container>

            <!-- Author Column -->
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
              <td mat-cell *matCellDef="let row">
                <button class="btn btn-primary" (click)="deleteClass(row)">
                  <mat-icon aria-hidden="false" aria-label="Delete">delete</mat-icon>
                </button>
              </td>
            </ng-container>


            <!-- <ng-container matColumnDef="action">



              <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
              <td mat-cell *matCellDef="let row">

              </td>




            </ng-container> -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr>
          </table>

          <mat-paginator #MatPaginator1 [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>



        <h3 *ngIf="!classesLoaded" style="text-align: center; margin-top: 40px;"><strong>Loading classes please
            wait</strong></h3>
      </div>
    </div>
  </div>
</ng-template>
