<div class="d-flex flex-column flex-root" id="kt_login_wrapper">
	<div class="login login-3 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white" id="kt_login">
		<!--begin::Aside-->
		<div class="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
			style="background-image: url(./assets/media/bg/bg-4.jpg);">
			<!--begin: Aside Container-->
			<div class="d-flex flex-row-fluid flex-column justify-content-between">
				<!--begin: Aside header-->
				<a href="#" class="flex-column-auto mt-5">
					<img src="./assets/media/logos/gravity-favicon.png" class="max-h-180px" />
				</a>
				<!--end: Aside header-->

				<!--begin: Aside content-->
				<div class="flex-column-fluid d-flex flex-column justify-content-center">
					<h3 class="font-size-h1 mb-5" style="color: black;"><b>Welcome to growOn</b></h3>
					<!-- <p class="font-weight-lighter text-white opacity-80">
						This is Gravity Description
					</p> -->
				</div>
				<!--end: Aside content-->

				<!--begin: Aside footer for desktop-->
				<div class="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
					<div class=" font-weight-bold">
						&copy; 2021 growOn
					</div>
					<div class="d-flex">
						<a href="https://growon.app/about/" style="color: #fff;"  ><b>About</b></a>
						&emsp;
						<a href="https://growon.app/contact/" style="color: #fff;" ><b>Contact</b></a>
					</div>
				</div>
				<!--end: Aside footer for desktop-->
			</div>

		</div>
		<!--begin::Aside-->

		<!--begin::Content-->
		<div class="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
			<!--begin::Content header-->
			<div
				class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
				<!-- <span class="font-weight-bold text-dark-50">
					Don't have an account yet?
				</span>
				<a
					href="javascript:;"
					routerLink="/auth/register"
					class="font-weight-bold ml-2"
					id="kt_login_signup"
				>{{ "AUTH.GENERAL.SIGNUP_BUTTON" | translate }}</a> -->
			</div>
			<!--end::Content header-->

			<router-outlet></router-outlet>
			<!--begin::Content footer for mobile-->
			<div class="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
				<div class=" font-weight-bold">
					&copy; 2021 growOn
				</div>
				<div class="d-flex">
					<a href="https://growon.app/about/" style="color: black;"  ><b>About</b></a>
					&nbsp;
					<a href="https://growon.app/contact/" style="color: black;" ><b>Contact</b></a>
				</div>
			</div>
			<!--end::Content footer for mobile-->
		</div>
		<!--end::Content-->
	</div>
</div>
