<ng-container *ngIf="type == 'All Questions'">
  <div class="import-questions-container">
    <!-- <form [formGroup]="filterQuestions" (ngSubmit)="onSubmit(filterQuestions.value)" class="form">
      <mat-form-field>
        <mat-label>Select Class</mat-label>
        <mat-select formControlName="class">
          <mat-option *ngFor="let class of classes" [value]="class._id">
            {{class.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form> -->
    <div class="row">
      <div class="col-md-6">
        <!-- <label>Select Filter Type</label>
        <select class="form-control" name="selectedFilter" [(ngModel)]="selectedFilter">
          <option selected disabled>Select Class</option>
          <option *ngFor="let filter of filterTypes" [value]="filter.value">{{filter.name}}</option>
        </select> -->
        <label>Select Class</label>
        <select class="form-control" name="selectedClass" [(ngModel)]="selectedClass">
          <option selected disabled>Select Class</option>
          <option *ngFor="let class of classes; let i =index" [value]="class.classId">{{class.name}}---{{i}}</option>
        </select>
      </div>
      <div class="col-md-6" *ngIf="selectedFilter">
        <ng-container *ngIf="selectedFilter == 'byClass'">
        </ng-container>
        <ng-container *ngIf="selectedFilter == 'byQType'">
        </ng-container>
        <ng-container *ngIf="selectedFilter == 'byChapter'">
        </ng-container>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6" *ngIf="selectedClass">
        <label>Select Question Type</label>
        <select class="form-control" name="selectedQuestionType" [(ngModel)]="selectedQuestionType">
          <option selected disabled>Select Question Type</option>
          <option *ngFor="let class of questionTypes" [value]="class.value">{{class.name}}</option>
        </select>
      </div>
      <div class="col-md-6" *ngIf="selectedQuestionType">
        <label>Select Chapter</label>
        <select class="form-control" name="selectedChapter" [(ngModel)]="selectedChapter">
          <option selected disabled>Select Chapter</option>
          <option *ngFor="let class of chapters" [value]="class._id">{{class.name}}</option>
        </select>
      </div>
    </div>
    <div style="margin-top:20px; margin-bottom: 10px;" *ngIf="selectedClass && selectedQuestionType && selectedChapter">
      <button (click)="getFilteredQuestions()" class="btn btn-primary">Get Questions</button>
    </div>
    <span *ngIf="filteredQuestions">Note: The questions which are checked are already present in your repository.</span>
    <table class="table" *ngIf="filteredQuestions">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Question Type</th>
          <th scope="col">Title</th>
          <th scope="col">Class</th>
          <th scope="col">Import</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let question of filteredQuestions; let i = index">
          <th scope="row">{{i+1}}</th>
          <td>{{question.questionType[0]}}</td>
          <td>{{question.questionTitle}}</td>
          <td>{{question.class}}</td>
          <td *ngIf="question._id == existingQuestions[i]._id"><input type="checkbox" class="form-control"
              style="font-size: 0rem;" (change)="checkedQuestion(question)" checked disabled></td>
          <td *ngIf="question._id != existingQuestions[i]._id"><input type="checkbox" class="form-control"
              style="font-size: 0rem;" (change)="checkedQuestion(question)"></td>
        </tr>
      </tbody>
      <tfoot>
        <button class="btn btn-primary" (click)="importQuestions()">Import</button>
      </tfoot>
    </table>
  </div>
</ng-container>
<ng-container *ngIf="type != 'All Questions'">
  <div class="classcontainer">
    <div class="modal-header">
      <h4 class="modal-title">Global {{title}}</h4>
    </div>

    <div class="modal-body px-0">
      <table mat-table [dataSource]="dataSource">


        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef> Description </th>
          <td mat-cell *matCellDef="let element"> {{element.description}} </td>
        </ng-container>

        <!-- Weight Column -->
        <!-- <ng-container matColumnDef="createdBy">
          <th mat-header-cell *matHeaderCellDef> Author </th>
          <td mat-cell *matCellDef="let element"> {{element.createdBy}} </td>
        </ng-container> -->


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
        </tr>
      </table>
    </div>
    <div class="modal-body px-0">
      <div class="col-md-12 import-btn ">

        <button class="btn btn-primary" (click)="submit()">Submit</button>
      </div>
    </div>
  </div>
</ng-container>
