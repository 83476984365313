<div class="bg">
  <h2>Enroll Students</h2>
  <div>
    <input accept="image/x-png,image/jpg,image/jpeg" type="file" style="display: none" #fileUpload (change)="onFileUpload($event)" />
    <div class="userPicDiv" (click)="fileUpload.click()">
      <span *ngIf="!filePreview">
        <img src="/assets/media/growon/logos/profilePicLogo.png" alt="" />
        <p>Click to add picture</p>
      </span>
      <span *ngIf="filePreview">
        <img [src]="filePreview" alt="" />
      </span>
    </div>
  </div>
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
    <mat-tab aria-label="primary">
      <ng-template mat-tab-label>
        <mat-icon>person</mat-icon>
        Signup
      </ng-template>
      <form [formGroup]="studentForm" autocomplete="off">
        <mat-accordion>
          <mat-expansion-panel expanded="true">
            <mat-expansion-panel-header>
              <mat-panel-title> Personal Details </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12">
                <!-- Name -->
                <mat-form-field appearance="fill">
                  <mat-label>Student Name*</mat-label>
                  <input matInput formControlName="name" />
                  <mat-error *ngIf="studentForm.get('name').hasError('required')">Student name required </mat-error>
                  <mat-error *ngIf="studentForm.get('name').hasError('maxlength')">You can only enter maximum of 50 character </mat-error>
                  <button *ngIf="studentForm.controls.name.value" matSuffix mat-icon-button aria-label="Clear" (click)="studentForm.controls.name.setValue('')">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-sm-4 col-xs-12">
                <!-- Gender -->
                <mat-radio-group formControlName="gender" color="primary">
                  <mat-label>Gender*</mat-label>
                  <mat-radio-button class="example-radio-button" *ngFor="let gend of gender" [value]="gend">
                    {{ gend }}
                  </mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="studentForm.controls.gender.value && studentForm.get('gender').hasError('required')"> gender is required </mat-error>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- Religion -->
                <mat-form-field appearance="fill">
                  <mat-label>Religion</mat-label>
                  <input matInput formControlName="religion" />
                  <!-- <mat-error *ngIf="studentForm.get('religion').hasError('required')">religion required </mat-error> -->
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- Caste -->
                <mat-form-field appearance="fill">
                  <mat-label>Caste</mat-label>
                  <input matInput formControlName="caste" />
                  <!-- <mat-error *ngIf="studentForm.get('caste').hasError('required')">caste required </mat-error> -->
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- Mother Tongue -->
                <mat-form-field appearance="fill">
                  <mat-label>Mother Tongue</mat-label>
                  <input matInput formControlName="motherTongue" />
                  <mat-error *ngIf="studentForm.get('motherTongue').hasError('required')">Mother tongue required </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- Blood Group -->
                <mat-form-field appearance="fill">
                  <mat-label>Blood Group</mat-label>
                  <mat-select placeholder="Blood Group" formControlName="bloodGroup">
                    <mat-option value="A+"> A- </mat-option>
                    <mat-option value="A-"> A- </mat-option>
                    <mat-option value="B+"> B+ </mat-option>
                    <mat-option value="B-"> B- </mat-option>
                    <mat-option value="O+"> O+ </mat-option>
                    <mat-option value="0-"> O- </mat-option>
                    <mat-option value="AB+"> AB+ </mat-option>
                    <mat-option value="AB-"> AB- </mat-option>
                  </mat-select>
                  <mat-error *ngIf="studentForm.get('bloodGroup').hasError('required')">blood group required </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12">
                <!-- Address -->
                <mat-form-field appearance="fill">
                  <mat-label>Address</mat-label>
                  <textarea matInput placeholder="Address" formControlName="address"></textarea>
                  <!-- <mat-error *ngIf="studentForm.get('address').hasError('required')">Address is required </mat-error> -->
                  <mat-error *ngIf="studentForm.get('address').hasError('maxlength')">You can only enter maximum of 150 character </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- Country -->
                <mat-form-field appearance="fill">
                  <mat-label>Select Country</mat-label>
                  <mat-select placeholder="Country" formControlName="country">
                    <mat-option *ngFor="let country of countries" [value]="country._id">
                      {{ country.country_name }}
                    </mat-option>
                  </mat-select>
                  <button *ngIf="studentForm.controls.country.value" matSuffix mat-icon-button aria-label="Clear" (click)="studentForm.controls.country.setValue('')">
                    <mat-icon>close</mat-icon>
                  </button>
                  <mat-error *ngIf="studentForm.get('country').hasError('required')">Please select a country </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- State -->
                <mat-form-field appearance="fill">
                  <mat-label>Select State</mat-label>
                  <mat-select placeholder="State" formControlName="state">
                    <mat-option *ngFor="let state of states" [value]="state._id">
                      {{ state.state_name }}
                    </mat-option>
                  </mat-select>
                  <button *ngIf="studentForm.controls.state.value" matSuffix mat-icon-button aria-label="Clear" (click)="studentForm.controls.state.setValue('')">
                    <mat-icon>close</mat-icon>
                  </button>
                  <mat-error *ngIf="studentForm.get('state').hasError('required')">Please select a state </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- City -->
                <mat-form-field appearance="fill">
                  <mat-label>Select City</mat-label>
                  <mat-select placeholder="City" formControlName="city">
                    <mat-option *ngFor="let city of cities" [value]="city._id">
                      {{ city.city_name }}
                    </mat-option>
                  </mat-select>
                  <button *ngIf="studentForm.controls.city.value" matSuffix mat-icon-button aria-label="Clear" (click)="studentForm.controls.city.setValue('')">
                    <mat-icon>close</mat-icon>
                  </button>
                  <mat-error *ngIf="studentForm.get('city').hasError('required')">please select a city </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12">
                <mat-form-field appearance="fill">
                  <mat-label>Pincode</mat-label>
                  <input matInput type="tel" formControlName="pinCode" />
                  <button *ngIf="studentForm.controls.pinCode.value" matSuffix mat-icon-button aria-label="Clear" (click)="studentForm.controls.pinCode.setValue('')">
                    <mat-icon>close</mat-icon>
                  </button>
                  <mat-error *ngIf="studentForm.get('pinCode').hasError('required')">pincode is required </mat-error>
                  <mat-error *ngIf="studentForm.get('pinCode').hasError('maxlength')">You can only enter maximum of 10 number </mat-error>
                  <mat-error *ngIf="studentForm.get('pinCode').hasError('pattern')"> pincode can only have numbers </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- Mode of Transportation -->
                <mat-form-field appearance="fill">
                  <mat-label>Mode of Transportation</mat-label>
                  <mat-select formControlName="transportation">
                    <mat-option *ngFor="let mode of transportationMode" [value]="mode">
                      {{ mode }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="studentForm.get('transportation').hasError('required')">transportation required </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- Medical Conditions (if Any) -->
                <mat-form-field appearance="fill">
                  <mat-label>Medical Conditions (if Any)</mat-label>
                  <mat-select formControlName="medicalConditions">
                    <mat-option *ngFor="let condition of medicalConditions" [value]="condition">
                      {{ condition }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="studentForm.get('medicalConditions').hasError('required')"> required </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12">
                <!-- Does the Child wear Glasses -->
                <mat-radio-group formControlName="glasses" color="primary">
                  <mat-label>Does the Child wear Glasses</mat-label>
                  <mat-radio-button class="example-radio-button" *ngFor="let bo of boolean" [value]="bo">{{ bo }} </mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="studentForm.get('glasses').hasError('required')"> required </mat-error>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- Email -->
                <mat-form-field appearance="fill">
                  <mat-label>Email</mat-label>
                  <input type="email" matInput formControlName="email" />
                  <mat-error *ngIf="studentForm.get('email').hasError('pattern')"> Invalid email format </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- DOB -->
                <mat-form-field appearance="fill">
                  <mat-label>Date of Birth (Please select from calender)</mat-label>
                  <input matInput [matDatepicker]="picker2" formControlName="dob" />
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                  <mat-error *ngIf="studentForm.get('dob').hasError('required')"> dob required </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- Aadhar card no -->
                <mat-form-field appearance="fill">
                  <mat-label>Aadhaar Card no</mat-label>
                  <input type="tel" matInput formControlName="aadhaarNo" />
                  <button *ngIf="studentForm.controls.aadhaarNo.value" matSuffix mat-icon-button aria-label="Clear" (click)="studentForm.controls.aadhaarNo.setValue('')">
                    <mat-icon>close</mat-icon>
                  </button>
                  <mat-error *ngIf="studentForm.get('aadhaarNo').hasError('maxlength')">you have reached maximum limit of characters </mat-error>
                  <mat-error *ngIf="studentForm.get('aadhaarNo').hasError('pattern')"> aadhaarNo can only have numbers </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-sm-4 col-xs-12">
                <mat-label>Birth Certificate</mat-label>
                <div>
                  <input #fileInput type="file" formControlName="birthCertificate" (change)="onFileInput($event,'birthCertificate')">
                </div>
                <!-- <div *ngIf="birthDoc">
                  <img [src]="birthDoc" />
                  <button (click)="cancelImage()">Cancel</button>
                  <button (click)="openDialog($event)">Preview</button>
                </div> -->
                
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12">
                <mat-label>Aadhaar Card</mat-label>
                <div>
                  <input #fileInput type="file" formControlName="aadharCard" (change)="onFileInput($event,'aadharCard')">
                </div>
              </div>
              <div class="col-md-4 col-sm-4 col-xs-12">
                <mat-label>Income Certificate</mat-label>
                <div>
                  <input #fileInput type="file" formControlName="incomeCertificate" (change)="onFileInput($event,'incomeCertificate')">
                </div>
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Academic Details </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <div class="col-md-3 col-sm-3 col-xs-12">
                <mat-form-field appearance="fill">
                  <mat-label>Class*</mat-label>
                  <mat-select (selectionChange)="primaryClassChange($event)" placeholder="Class" formControlName="class">
                    <mat-option *ngFor="let c of class" [value]="c.classId">
                      {{ c.className }}
                    </mat-option>
                  </mat-select>
                  <button *ngIf="studentForm.controls.class.value" matSuffix mat-icon-button aria-label="Clear" (click)="studentForm.controls.class.setValue('')">
                    <mat-icon>close</mat-icon>
                  </button>
                  <mat-error *ngIf="studentForm.get('class').hasError('required') || classRequiredFlag"> Please select the class </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-3">
                <mat-form-field appearance="fill">
                  <mat-label>Section/Division</mat-label>
                  <mat-select placeholder="Section/Division" formControlName="section">
                    <mat-option *ngFor="let section of sections" [value]="section._id">{{ section.name }}</mat-option>
                  </mat-select>
                  <button *ngIf="studentForm.controls.section.value" matSuffix mat-icon-button aria-label="Clear" (click)="studentForm.controls.section.setValue('')">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-3">
                <mat-form-field appearance="fill">
                  <mat-label>Branch*</mat-label>
                  <mat-select placeholder="Branch" formControlName="branch">
                    <mat-option *ngFor="let branch of branches" [value]="branch._id">
                      {{ branch.name }}
                    </mat-option>
                  </mat-select>
                  <button *ngIf="studentForm.controls.branch.value" matSuffix mat-icon-button aria-label="Clear" (click)="studentForm.controls.branch.setValue('')">
                    <mat-icon>close</mat-icon>
                  </button>
                  <mat-error *ngIf="studentForm.get('branch').hasError('required')">Please select the branch</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- Admission Number -->
                <mat-form-field appearance="fill">
                  <mat-label>Admission Number</mat-label>
                  <input type="text" matInput placeholder="Admission Number" formControlName="admissionno" />
                  <button *ngIf="studentForm.controls.admissionno.value" matSuffix mat-icon-button aria-label="Clear" (click)="studentForm.controls.admissionno.setValue('')">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- STS no -->
                <mat-form-field appearance="fill">
                  <mat-label>STS no</mat-label>
                  <input matInput formControlName="STSNo" />
                  <button *ngIf="studentForm.controls.STSNo.value" matSuffix mat-icon-button aria-label="Clear" (click)="studentForm.controls.STSNo.setValue('')">
                    <mat-icon>close</mat-icon>
                  </button>
                  <!-- <mat-error *ngIf="studentForm.get('STSNo').hasError('required')">STSNo required </mat-error> -->
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- RTE Student -->
                <mat-form-field appearance="fill">
                  <mat-label>RTE Student</mat-label>
                  <input matInput formControlName="RTEStudent" />
                  <button *ngIf="studentForm.controls.RTEStudent.value" matSuffix mat-icon-button aria-label="Clear" (click)="studentForm.controls.RTEStudent.setValue('')">
                    <mat-icon>close</mat-icon>
                  </button>
                  <!-- <mat-error *ngIf="studentForm.get('RTEStudent').hasError('required')">RTE required </mat-error> -->
                </mat-form-field>
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Parent/Guardian Details </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <div class="col-md-4 col-sm-4 col-xs-4">
                <mat-form-field appearance="fill">
                  <mat-label>Primary Parent / Guardian*</mat-label>
                  <mat-select formControlName="primaryParent">
                    <mat-option *ngFor="let primary of primaryParent" [value]="primary.value">
                      {{ primary.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="studentForm.get('primaryParent').hasError('required')">Primary parent is required </mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-8 col-sm-8 col-xs-8" style="display: flex; align-items: flex-start">
                <h4>Note: The mobile number of this parent/guardian will be user for parent login</h4>
              </div>
            </div>
            <!-- Father -->
            <h1>Father</h1>

            <div class="row">
              <!-- Father Name -->
              <div class="col-md-3 col-sm-3 col-xs-12">
                <mat-form-field appearance="fill">
                  <mat-label>Father Name</mat-label>
                  <input matInput formControlName="fatherName" />
                  <mat-error *ngIf="studentForm.get('fatherName').hasError('required')">father name required </mat-error>
                  <mat-error *ngIf="studentForm.get('fatherName').hasError('maxlength')">You can only enter maximum of 50 character </mat-error>
                </mat-form-field>
              </div>

              <!-- Contact -->
              <div class="col-md-3 col-sm-3 col-xs-12">
                <mat-form-field appearance="fill">
                  <mat-label>Father Contact</mat-label>
                  <input type="number" matInput (blur)="checkParentValidation('father')" formControlName="fatherContact" />
                  <mat-error *ngIf="studentForm.get('fatherContact').hasError('required')">father contact required </mat-error>
                </mat-form-field>
                <span class="text-danger" *ngIf="parentNumberExist[0]"> Mobile number is already in use. </span>
              </div>

              <!-- Email -->
              <div class="col-md-3 col-sm-3 col-xs-12">
                <mat-form-field appearance="fill">
                  <mat-label>Father Email</mat-label>
                  <input type="email" matInput formControlName="fatherEmail" />
                  <mat-error *ngIf="studentForm.get('fatherEmail').hasError('pattern')"> Invalid email format </mat-error>
                </mat-form-field>
              </div>

              <!-- Qualification -->
              <div class="col-md-3 col-sm-3 col-xs-12">
                <mat-form-field appearance="fill">
                  <mat-label>Father Qualification</mat-label>
                  <mat-select formControlName="fatherQualification">
                    <mat-option *ngFor="let qualification of parentQualification" [value]="qualification">
                      {{ qualification }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="studentForm.get('fatherQualification').hasError('required')"> required </mat-error>
                  <button *ngIf="studentForm.controls.fatherQualification.value" matSuffix mat-icon-button aria-label="Clear" (click)="studentForm.controls.fatherQualification.setValue('')">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <!-- Occupation -->
              <div class="col-md-12">
                <mat-form-field appearance="fill">
                  <mat-label>Occupation</mat-label>
                  <input type="tel" matInput formControlName="fatherOccupation" />
                </mat-form-field>
              </div>

              <!-- Aadhar -->
              <div class="col-md-12">
                <mat-form-field appearance="fill">
                  <mat-label>Aadhaar Card no</mat-label>
                  <input type="tel" matInput formControlName="fatheraadhaarNo" />
                  <mat-error *ngIf="studentForm.get('fatheraadhaarNo').hasError('maxlength')">you have reached maximum limit of characters </mat-error>
                  <mat-error *ngIf="studentForm.get('fatheraadhaarNo').hasError('pattern')"> aadhaarNo can only have numbers </mat-error>
                  <!-- <mat-error *ngIf="studentForm.get('fatheraadhaarNo').hasError('required')"> required </mat-error> -->
                </mat-form-field>
              </div>
                <div class="col-md-12">
                  <mat-label>Father Aadhaar</mat-label>
                  <div>
                    <input #fileInput type="file" formControlName="fatherAadhaar" (change)="onFileInput($event,'fatherAadhaar')">
                  </div>
                </div>
            </div>
            <br />
            <!-- Language Proficiency -->
            <h4>Language Proficiency</h4>
            <br />
            <div class="row" *ngFor="let count of countOfFour">
              <div class="col-md-3 col-sm-3 col-xs-3">
                <mat-form-field appearance="fill">
                  <mat-label>Language {{ count }}</mat-label>
                  <input matInput formControlName="fatherLanguage{{ count }}" />
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-3">
                <!-- <mat-form-field> -->
                <mat-label>Read</mat-label>
                <ng-select placeholder="Read" formControlName="fatherLanguageRead{{ count }}">
                  <ng-option *ngFor="let boo of boolean" [value]="boo">
                    {{ boo }}
                  </ng-option>
                </ng-select>

                <!-- </mat-form-field> -->
              </div>
              <div class="col-md-3 col-sm-3 col-xs-3">
                <!-- <mat-form-field> -->
                <mat-label>Write</mat-label>
                <ng-select placeholder="Read" formControlName="fatherLanguageWrite{{ count }}">
                  <ng-option *ngFor="let boo of boolean" [value]="boo">
                    {{ boo }}
                  </ng-option>
                </ng-select>
                <!-- </mat-form-field> -->
              </div>
              <div class="col-md-3 col-sm-3 col-xs-3">
                <!-- <mat-form-field> -->
                <mat-label>Speak</mat-label>
                <ng-select placeholder="Read" formControlName="fatherLanguageSpeak{{ count }}">
                  <ng-option *ngFor="let boo of boolean" [value]="boo">
                    {{ boo }}
                  </ng-option>
                </ng-select>
                <!-- </mat-form-field> -->
              </div>
            </div>

            <!-- Mother -->
            <h1>Mother</h1>
            <div class="row">
              <div class="col-md-3 col-sm-3 col-xs-12">
                <mat-form-field appearance="fill">
                  <mat-label>Mother Name</mat-label>
                  <input matInput formControlName="motherName" />
                  <mat-error *ngIf="studentForm.get('motherName').hasError('required')">mother name required </mat-error>
                  <mat-error *ngIf="studentForm.get('motherName').hasError('maxlength')">You can only enter maximum of 50 character </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- Contact -->
                <mat-form-field appearance="fill">
                  <mat-label>Mother Contact</mat-label>
                  <input type="number" matInput (blur)="checkParentValidation('mother')" formControlName="motherContact" />
                  <mat-error *ngIf="studentForm.get('motherContact').hasError('required')">mother contact required </mat-error>
                </mat-form-field>
                <span class="text-danger" *ngIf="parentNumberExist[1]"> Mobile number is already in use. </span>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- Email -->
                <mat-form-field appearance="fill">
                  <mat-label>Mother Email</mat-label>
                  <input type="email" matInput formControlName="motherEmail" />
                  <mat-error *ngIf="studentForm.get('motherEmail').hasError('pattern')"> Invalid email format </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- Qualification -->
                <mat-form-field appearance="fill">
                  <mat-label>Mother Qualification</mat-label>
                  <!-- <input matInput placeholder="Mother Name" formControlName="motherQualification" class="form-control"> -->
                  <mat-select placeholder="Mother Qualification" formControlName="motherQualification">
                    <mat-option *ngFor="let qualification of parentQualification" [value]="qualification">
                      {{ qualification }}
                    </mat-option>
                  </mat-select>
                  <button *ngIf="studentForm.controls.motherQualification.value" matSuffix mat-icon-button aria-label="Clear" (click)="studentForm.controls.motherQualification.setValue('')">
                    <mat-icon>close</mat-icon>
                  </button>
                  <mat-error *ngIf="studentForm.get('motherQualification').hasError('required')">required </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <!-- Occupation -->
              <div class="col-md-12">
                <mat-form-field appearance="fill">
                  <mat-label>Occupation</mat-label>
                  <input type="tel" matInput formControlName="motherOccupation" />
                </mat-form-field>
              </div>
              <!-- Aadhar -->
              <div class="col-md-12">
                <mat-form-field appearance="fill">
                  <mat-label>Aadhaar Card no</mat-label>
                  <input type="tel" matInput placeholder="Aadhaar Card no" formControlName="motheraadhaarNo" />
                  <mat-error *ngIf="studentForm.get('motheraadhaarNo').hasError('maxlength')">you have reached maximum limit of characters </mat-error>
                  <mat-error *ngIf="studentForm.get('motheraadhaarNo').hasError('pattern')"> aadhaarNo can only have numbers </mat-error>
                  <!-- <mat-error *ngIf="studentForm.get('motheraadhaarNo').hasError('required')"> required </mat-error> -->
                </mat-form-field>
              </div>
                <div class="col-md-12">
                  <mat-label>Mother Aadhaar</mat-label>
                  <div>
                    <input #fileInput type="file" formControlName="motherAadhaar" (change)="onFileInput($event,'motherAadhaar')">
                  </div>
                </div>
            </div>
            <br />
            <!-- Language Proficiency -->
            <h1>Language Proficiency</h1>
            <div class="row" *ngFor="let count of countOfFour">
              <div class="col-md-3 col-sm-3 col-xs-3">
                <mat-form-field appearance="fill">
                  <mat-label>Language {{ count }}</mat-label>
                  <input matInput formControlName="motherLanguage{{ count }}" />
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-3">
                <!-- <mat-form-field> -->
                <mat-label>Read</mat-label>
                <ng-select placeholder="Read" formControlName="motherLanguageRead{{ count }}">
                  <ng-option *ngFor="let boo of boolean" [value]="boo">
                    {{ boo }}
                  </ng-option>
                </ng-select>
                <!-- </mat-form-field> -->
              </div>
              <div class="col-md-3 col-sm-3 col-xs-3">
                <!-- <mat-form-field> -->
                <mat-label>Write</mat-label>
                <ng-select placeholder="Read" formControlName="motherLanguageWrite{{ count }}">
                  <ng-option *ngFor="let boo of boolean" [value]="boo">
                    {{ boo }}
                  </ng-option>
                </ng-select>
                <!-- </mat-form-field> -->
              </div>
              <div class="col-md-3 col-sm-3 col-xs-3">
                <!-- <mat-form-field> -->
                <mat-label>Speak</mat-label>
                <ng-select placeholder="Read" formControlName="motherLanguageSpeak{{ count }}">
                  <ng-option *ngFor="let boo of boolean" [value]="boo">
                    {{ boo }}
                  </ng-option>
                </ng-select>
                <!-- </mat-form-field> -->
              </div>
            </div>
            <!-- Guardian -->
            <h1>Guardian</h1>
            <div class="row">
              <div class="col-md-3 col-sm-3 col-xs-12">
                <mat-form-field appearance="fill">
                  <mat-label>Guardian Name</mat-label>
                  <input matInput formControlName="guardianName" />
                  <mat-error *ngIf="studentForm.get('guardianName').hasError('required')">guardian name required </mat-error>
                  <mat-error *ngIf="studentForm.get('guardianName').hasError('maxlength')">You can only enter maximum of 50 character </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- Contact -->
                <mat-form-field appearance="fill">
                  <mat-label>Guardian Contact</mat-label>
                  <input type="number" matInput (blur)="checkParentValidation('guardian')" formControlName="guardianContact" />
                  <mat-error *ngIf="studentForm.get('guardianContact').hasError('required')">guardian contact required </mat-error>
                </mat-form-field>
                <span class="text-danger" *ngIf="parentNumberExist[2]"> Mobile number is already in use. </span>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- Email -->
                <mat-form-field appearance="fill">
                  <mat-label>Guardian Email</mat-label>
                  <input type="email" matInput formControlName="guardianEmail" />
                  <mat-error *ngIf="studentForm.get('guardianEmail').hasError('pattern')"> Invalid email format </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-12">
                <!-- Qualification -->
                <mat-form-field appearance="fill">
                  <mat-label>Guardian Qualification</mat-label>
                  <!-- <input matInput placeholder="Guardian Name" formControlName="guardianQualification" class="form-control"> -->
                  <mat-select formControlName="guardianQualification">
                    <mat-option *ngFor="let qualification of parentQualification" [value]="qualification">
                      {{ qualification }}
                    </mat-option>
                  </mat-select>
                  <button *ngIf="studentForm.controls.guardianQualification.value" matSuffix mat-icon-button aria-label="Clear" (click)="studentForm.controls.guardianQualification.setValue('')">
                    <mat-icon>close</mat-icon>
                  </button>
                  <mat-error *ngIf="studentForm.get('guardianQualification').hasError('required')"> required </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <!-- Occupation -->
              <div class="col-md-12">
                <mat-form-field appearance="fill">
                  <mat-label>Occupation</mat-label>
                  <input type="tel" matInput formControlName="guardianOccupation" />
                </mat-form-field>
              </div>
              <!-- Aadhar -->
              <div class="col-md-12">
                <mat-form-field appearance="fill">
                  <mat-label>Aadhaar Card no</mat-label>
                  <input type="tel" matInput formControlName="guardianaadhaarNo" />
                  <mat-error *ngIf="studentForm.get('guardianaadhaarNo').hasError('maxlength')">you have reached maximum limit of characters </mat-error>
                  <mat-error *ngIf="studentForm.get('guardianaadhaarNo').hasError('pattern')"> aadhaarNo can only have numbers </mat-error>
                  <!-- <mat-error *ngIf="studentForm.get('guardianaadhaarNo').hasError('required')"> required </mat-error> -->
                </mat-form-field>
              </div>
            </div>
            <!-- Language Proficiency -->
            <h1>Language Proficiency</h1>
            <div class="row" *ngFor="let count of countOfFour">
              <div class="col-md-3 col-sm-3 col-xs-3">
                <mat-form-field appearance="fill">
                  <mat-label>Language {{ count }}</mat-label>
                  <input matInput placeholder="Language {{ count }}" formControlName="guardianLanguage{{ count }}" />
                </mat-form-field>
              </div>
              <div class="col-md-3 col-sm-3 col-xs-3">
                <!-- <mat-form-field> -->
                <mat-label>Read</mat-label>
                <ng-select placeholder="Read" formControlName="guardianLanguageRead{{ count }}">
                  <ng-option *ngFor="let boo of boolean" [value]="boo">
                    {{ boo }}
                  </ng-option>
                </ng-select>
                <!-- </mat-form-field> -->
              </div>
              <div class="col-md-3 col-sm-3 col-xs-3">
                <!-- <mat-form-field> -->
                <mat-label>Write</mat-label>
                <ng-select placeholder="Read" formControlName="guardianLanguageWrite{{ count }}">
                  <ng-option *ngFor="let boo of boolean" [value]="boo">
                    {{ boo }}
                  </ng-option>
                </ng-select>
                <!-- </mat-form-field> -->
              </div>
              <div class="col-md-3 col-sm-3 col-xs-3">
                <!-- <mat-form-field> -->
                <mat-label>Speak</mat-label>
                <ng-select placeholder="Read" formControlName="guardianLanguageSpeak{{ count }}">
                  <ng-option *ngFor="let boo of boolean" [value]="boo">
                    {{ boo }}
                  </ng-option>
                </ng-select>
                <!-- </mat-form-field> -->
              </div>
            </div>

            <!-- <mat-form-field> -->

            <!-- <button class="btn btn-primary" (click)="createStudent()">Submit</button> -->

            <!-- </mat-form-field> -->
          </mat-expansion-panel>
        </mat-accordion>

        <div class="import-btn">
          <button class="btn btn-primary" (click)="createStudent()" [disabled]="!studentForm.valid">Submit</button>
        </div>
      </form>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>flash_on</mat-icon>
        Quick Signup
      </ng-template>
      <form [formGroup]="qstudentForm">
        <div style="display: flex; flex-direction: column">
          <div class="row">
            <!-- Name -->
            <div class="col-md-12 col-sm-12 col-xs-12">
              <!-- Name -->
              <mat-form-field appearance="fill">
                <mat-label>Student Name*</mat-label>
                <input matInput formControlName="studentName" />
                <mat-error *ngIf="qstudentForm.get('studentName').hasError('required')">Student name required </mat-error>
                <mat-error *ngIf="qstudentForm.get('studentName').hasError('maxlength')">You can only enter maximum of 50 character </mat-error>
                <button *ngIf="qstudentForm.controls.studentName.value" matSuffix mat-icon-button aria-label="Clear" (click)="qstudentForm.controls.studentName.setValue('')">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <!-- Gender -->

            <div class="col-md-4 col-sm-4 col-xs-12">
              <mat-radio-group formControlName="gender" color="primary">
                <mat-label>Gender*</mat-label>
                <mat-radio-button class="example-radio-button" *ngFor="let gend of gender" [value]="gend">
                  {{ gend }}
                </mat-radio-button>
              </mat-radio-group>
              <mat-error *ngIf="qstudentForm.controls.gender.value && qstudentForm.get('gender').hasError('required')"> gender is required </mat-error>
            </div>
          </div>
          <br />
          <h3>Academic Details</h3>
          <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-12">
              <mat-form-field appearance="fill">
                <mat-label>Class*</mat-label>
                <mat-select (selectionChange)="primaryClassChange($event)" placeholder="Class" formControlName="class">
                  <mat-option *ngFor="let c of class" [value]="c.classId">
                    {{ c.className }}
                  </mat-option>
                </mat-select>
                <button *ngIf="qstudentForm.controls.class.value" matSuffix mat-icon-button aria-label="Clear" (click)="qstudentForm.controls.class.setValue('')">
                  <mat-icon>close</mat-icon>
                </button>
                <mat-error *ngIf="qstudentForm.get('class').hasError('required') || classRequiredFlag"> Please select the class </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3">
              <mat-form-field appearance="fill">
                <mat-label>Section/Division</mat-label>
                <mat-select placeholder="Section/Division" formControlName="section">
                  <mat-option *ngFor="let section of sections" [value]="section._id">{{ section.name }}</mat-option>
                </mat-select>
                <button *ngIf="qstudentForm.controls.section.value" matSuffix mat-icon-button aria-label="Clear" (click)="qstudentForm.controls.section.setValue('')">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </div>
          <br />
          <h3>Parent Details</h3>
          <div class="row">
            <!-- Primary Parent / Guardian -->
            <div class="col-md-4 col-sm-4 col-xs-4">
              <mat-form-field appearance="fill">
                <mat-label>Primary Parent / Guardian </mat-label>
                <mat-select formControlName="parentType">
                  <mat-option *ngFor="let primary of primaryParent" [value]="primary.value">
                    {{ primary.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="qstudentForm.get('parentType').hasError('required')">Primary parent is required </mat-error>
              </mat-form-field>
            </div>

            <!-- Parent/ Guardian Name -->
            <div class="col-md-4 col-sm-4 col-xs-4">
              <mat-form-field appearance="fill">
                <mat-label>Parent / Guardian Name</mat-label>
                <input matInput formControlName="parentName" />
                <mat-error *ngIf="qstudentForm.get('parentName').hasError('required')">father name required </mat-error>
                <mat-error *ngIf="qstudentForm.get('parentName').hasError('maxlength')">You can only enter maximum of 50 character </mat-error>
              </mat-form-field>
            </div>

            <!-- Parent/ Guardian Contact Number -->
            <div class="col-md-4 col-sm-4 col-xs-4">
              <mat-form-field appearance="fill">
                <mat-label>Parent / Guardian Contact</mat-label>
                <input type="number" matInput (input)="checkNumber()" formControlName="phone">
                <mat-error *ngIf="qstudentForm.get('phone').hasError('required')">father contact required</mat-error>
              </mat-form-field>
              <span class="text-danger" *ngIf="parentNumberExist[0]"> Mobile number is already in use. </span>
            </div>
          </div>

          <div class="import-btn">
            <button class="btn btn-primary" (click)="quickSignup()">Add Student</button>
          </div>
        </div>
      </form>
    </mat-tab>
  </mat-tab-group>
</div>
