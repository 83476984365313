<div class="main">
  <h3>Select Download Type</h3>
  <button mat-button mat-flat-button color="primary" style="color: #000;margin: 0px 0px 19px 20px"
    (click)="close('current')">
    Download {{current}} Students
  </button>
  <button mat-button mat-flat-button color="primary" style="color: #000;margin: 0px 0px 19px 20px"
    (click)="close('all')">
    Download all Students
  </button>
</div>