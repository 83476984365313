<h3>All Question Papers</h3>
<!-- <table class="table">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Name</th>
        <th scope="col">Created By</th>
        <th scope="col">Total Questions</th>
        <th scope="col">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let paper of questionPapers; let i = index">
        <th scope="row">{{i+1}}</th>
        <td>{{paper.question_title}}</td>
        <td>{{paper.createdBy}}</td>
        <td>{{paper.question_list.length}}</td>
        <td><button class="btn btn-primary" (click)="openQuestionPaper(paper)" >View</button></td>
      </tr>
    </tbody>
  </table> -->

  <!-- <div class="import-btn">
    <button class="btn btn-primary" (click)="import()">Import</button>
  </div> -->
<mat-form-field>
  <mat-label>Filter</mat-label>
  <input matInput placeholder="Search a Role" (keyup)="applyFilter($event)" #input>
</mat-form-field>

<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>

    <!-- question title Column -->
    <ng-container matColumnDef="question_title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let row"> {{row.question_title}} </td>
    </ng-container>
    <ng-container matColumnDef="class">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> class </th>
      <td mat-cell *matCellDef="let row"> {{row.class_id?.name }} </td>
    </ng-container>
    <!-- created by Column -->
    <ng-container matColumnDef="createdBy">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Created By </th>
      <td mat-cell *matCellDef="let row"> {{row.createdBy}} </td>
    </ng-container>

    <!-- question list Column -->
    <!-- <ng-container matColumnDef="question_list">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Question type </th>
      <td mat-cell *matCellDef="let row"> {{row.question_list.length}} </td>
    </ng-container> -->


    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
      <td mat-cell *matCellDef="let row">
        <button class="btn btn-primary" (click)="openQuestionPaper(row)">
          <mat-icon aria-hidden="false" aria-label="Update">edit</mat-icon>
        </button>
        <button class="btn btn-primary" (click)="deleteQuestionPaper(row)">
          <mat-icon aria-hidden="false" aria-label="Delete">delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
    </tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>
