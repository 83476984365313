<h3>All Students</h3>

<mat-accordion *ngIf="schoolId">
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Student
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div style="display: flex;flex-direction: row;" *ngIf="schoolId">
      <div class="chart-container">
        <h3>Blood Group</h3>
        <canvas id="blood-data-chart" style="padding-bottom: 20px;"></canvas>
      </div>

      <div class="chart-container">
        <h3>Mode of Transportation</h3>
        <canvas id="motCanvas" style="padding-bottom: 20px;"></canvas>
      </div>

      <div class="chart-container">
        <h3>Wear Glasses</h3>
        <canvas id="wgCanvas" style="padding-bottom: 20px;"></canvas>
      </div>
    </div>

    <div style="display: flex;flex-direction: row;" *ngIf="schoolId">

      <div class="chart-container">
        <h3>Medical Conditions</h3>
        <canvas id="medCanvas" style="padding-bottom: 20px;"></canvas>
      </div>

      <div class="chart-container">
        <h3>Mother Tongue</h3>
        <canvas id="mtCanvas" style="padding-bottom: 20px;"></canvas>
      </div>

      <div class="chart-container">
        <h3>Gender</h3>
        <canvas id="gCanvas" style="padding-bottom: 20px;"></canvas>
      </div>
    </div>
  </mat-expansion-panel>

  <!-- <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Parent
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div style="display: flex;flex-direction: row;" *ngIf="schoolId">
      <div class="chart-container">
        <h3>Language proficiency</h3>
        <canvas id="langCanvas" style="padding-bottom: 20px;"></canvas>
      </div>
      <div class="chart-container">
        <h3>Occupation</h3>
        <canvas id="occupCanvas" style="padding-bottom: 20px;"></canvas>
      </div>
      <div class="chart-container">
        <h3>Qualification</h3>
        <canvas id="qualCanvas" style="padding-bottom: 20px;"></canvas>
      </div>
    </div>
  </mat-expansion-panel> -->
</mat-accordion>

<div style="display: flex;flex-direction: row;align-items: center;justify-content: space-between;">
  <div>
    <button mat-button (click)="openDialog()" mat-flat-button color="primary" style="color: #000;margin-top: 20px;">
      <mat-icon>filter_list</mat-icon>Filter
    </button>
    <button mat-flat-button color="primary" style="color: #000;margin-top: 20px;" *ngIf="filters"
      (click)="clearFilters()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-chip-list>
    <mat-chip selected
      style="color: #000;background-color: white;box-shadow: 0px 0px 05px 01px rgba(0, 0, 0, 0.068);margin-top: 25px;">
      {{resultsLength}} Results Found</mat-chip>
  </mat-chip-list>
</div>
<div style="margin-top: 20px;display: flex;flex-direction: row;">
  <mat-form-field class="example-full-width" appearance="fill">
    <mat-label>Search</mat-label>
    <input #searchInput matInput placeholder="Student Name" aria-label="Student Name" [matAutocomplete]="auto"
      [formControl]="searchCtrl" [(ngModel)]="searchText" (keypress)="search(1,5)" (keyup)="0">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option (onSelectionChange)=" onEnter($event)" *ngFor="let item of filteredItems | async" [value]="item">
        <span>{{ item.name }} - {{ item.className }} {{ item.section}}</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <!-- <mat-form-field class="example-full-width" appearance="fill">
    <mat-label>Search</mat-label>
    <input matInput [(ngModel)]="searchText" (keyup)="applyFilter(1,5)">
    <button *ngIf="searchText" matSuffix mat-icon-button aria-label="Clear" (click)="clearText()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field> -->

  <!-- </mat-form-field> -->
  <button mat-button (click)="applyFilter(1,5)" mat-flat-button color="primary"
    style="color: #000;margin: 0px 0px 19px 20px">
    <mat-icon>search</mat-icon>
  </button>
  <button mat-button (click)="openDialogforStudentsDownload()" mat-flat-button color="primary"
    style="color: #000;margin: 0px 0px 19px 20px">
    <mat-icon>
      arrow_downward
    </mat-icon>
  </button>
  <button [disabled]="checkedStudents.length == 0" mat-button (click)="deleteStudents()" mat-flat-button color="primary"
    style="color: #000;margin: 0px 0px 19px 20px;">
    <mat-icon>delete</mat-icon>
  </button>
</div>
<div class="teacher-table">
  <div class="mat-elevation-z8" #TABLE>
    <table mat-table [dataSource]="dataSource" style="width: 100%;">
      <!-- CheckBox Column -->
      <ng-container matColumnDef="checkbox">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox (change)="toggleCheckbox(element._id, $event.checked)"
            [checked]="isStudentsChecked(element._id)">
          </mat-checkbox>
        </td>
      </ng-container>


      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <!-- Phone Column -->
      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef> Phone </th>
        <td mat-cell *matCellDef="let element"> {{element.username}} </td>
      </ng-container>

      <!-- Gender Column -->
      <ng-container matColumnDef="gender">
        <th mat-header-cell *matHeaderCellDef> Gender </th>
        <td mat-cell *matCellDef="let element"> {{element.gender ? (element.gender | titlecase) : '-'}} </td>
      </ng-container>

      <!-- Class Column -->
      <ng-container matColumnDef="class">
        <th mat-header-cell *matHeaderCellDef> Class </th>
        <td mat-cell *matCellDef="let element"> {{element.class ? element.class?.name : '-'}} </td>
      </ng-container>

      <!-- Section Column -->
      <ng-container matColumnDef="section">
        <th mat-header-cell *matHeaderCellDef> Section </th>
        <td mat-cell *matCellDef="let element"> {{element.section ? element.section?.name : '-'}} </td>
      </ng-container>

      <!-- School Column -->
      <ng-container matColumnDef="school">
        <th mat-header-cell *matHeaderCellDef> School </th>
        <td mat-cell *matCellDef="let element"> {{element.school_id?.schoolName}} </td>
      </ng-container>



      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element">
          <mat-slide-toggle [checked]="element.activeStatus" (click)="deactivateAccount(element)">
          </mat-slide-toggle>
        </td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element">
          <button mat-flat-button color="primary" (click)="edit(element)" [disabled]="!schoolId">
            <mat-icon> edit </mat-icon>
          </button>
          <button mat-flat-button color="primary" (click)="deleteUser(element)">
            <mat-icon> delete </mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20, 100,totalStudents]" [length]="resultsLength"
      (page)="onPageFired($event)" [pageSize]="pageSize" [pageIndex]="pageIndex">
    </mat-paginator>
  </div>
</div>