<h3>Statistics</h3>
<div class="header">
  <mat-form-field class="filter" style="width: 70%;margin: 10px 0px 5px 0px;">
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
  </mat-form-field>
  <mat-form-field appearance="fill" style="width: 30%;margin: 15px;">
    <mat-label>Choose a date</mat-label>
    <input matInput [matDatepicker]="picker" [(ngModel)]="date" (dateInput)="dateFilter($event.target.value)">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <div style="display:flex;justify-content:end">
    <button mat-flat-button color="primary" style="color: #000;height: 7%;margin:0px 15px;margin-bottom:20px"
      (click)="ExportTOExcel()">Export</button>
  </div>
</div>


<div class="mat-elevation-z8" #TABLE>
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="SchoolName">
      <th mat-header-cell *matHeaderCellDef> School Name </th>
      <td mat-cell *matCellDef="let element"> {{element.schoolName}} </td>
    </ng-container>
    <ng-container matColumnDef="noofstudents">
      <th mat-header-cell *matHeaderCellDef> No of Students </th>
      <td mat-cell *matCellDef="let element"> {{element.totalStudents ? element.present_count + '/' +
        element.totalStudents : '-'}} </td>
    </ng-container>

    <ng-container matColumnDef="usage">
      <th mat-header-cell *matHeaderCellDef> Percentage </th>
      <td mat-cell *matCellDef="let element"> {{element.usage ? (element.usage | number: '1.0-1') + '%' :
        '-'}}
      </td>
    </ng-container>
    <ng-container matColumnDef="percentage">
      <th mat-header-cell *matHeaderCellDef> Usage </th>
      <td mat-cell *matCellDef="let element"> {{element.percentage ? (element.percentage | number: '1.0-0') + '%' :
        '-'}}
      </td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef> Action </th>
      <td mat-cell *matCellDef="let element;let i = index">
        <button *ngIf="element.totalStudents" mat-raised-button
          (click)="schoolData(element.school_id,element.usage)">Details</button>
        <button *ngIf="!element.totalStudents" mat-raised-button disabled>Details</button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20, 100,totalCount]" showFirstLastButtons>
  </mat-paginator>
</div>