<div>
  <div class="header">
    <h3>Report of {{schoolName}} - {{percentage}}</h3>
  </div>
  <div style="display:flex;flex-direction: row;align-items: center;">
    <mat-form-field class="filter" style="width: 70%;margin: 15px;">
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Choose a date</mat-label>
      <input matInput [matDatepicker]="picker" [(ngModel)]="date" (dateInput)="dateFilter($event.target.value)">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <button mat-flat-button color="primary" style="color: #000;height: 7%;margin:0px 15px;margin-bottom:20px"
      (click)="ExportTOExcel()">Export</button>
  </div>

</div>
<div class="mat-elevation-z8" #TABLE>
  <table mat-table [dataSource]="dataSource">

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <!-- Marked At Column -->
    <ng-container matColumnDef="time">
      <th mat-header-cell *matHeaderCellDef> Marked At </th>
      <td mat-cell *matCellDef="let element">
        {{ element.createdAt}}
      </td>
    </ng-container>


    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> Status </th>
      <td mat-cell *matCellDef="let element">
        <button class="status" *ngIf="element.status === 'PRESENT'" mat-button
          style="background-color: #009879;color: white;">
          Present
        </button>
        <button class="status" *ngIf="element.status === 'ABSENT'" mat-button
          style="background-color: #cb1939;color: white">
          Absent
        </button>
        <button class="status" *ngIf="element.status === 'PARTIAL'" mat-button
        style="background-color:rgba(70, 191, 243, 1);color: white;">
        Excused
      </button>
      <button class="status" *ngIf="element.status === 'LATE'" mat-button
      style="background-color:rgba(245, 188, 40, 1);color: white;">
      Late
    </button>
      </td>
    </ng-container>




    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20, 100,totalCount]" showFirstLastButtons>
  </mat-paginator>

</div>