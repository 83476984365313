
<div class="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">

  <!-- forgot-password page -->
  <ng-container *ngIf="isForgotPassPage">
    <!--begin::Signin-->
  <div class="login-form login-signin">
    <div class="text-center mb-10 mb-lg-20">
      <h3 class="font-size-h1">OTP</h3>
    </div>

    <!-- <kt-auth-notice></kt-auth-notice> -->
    <div class="text-center toggle-position">
      <p class="forgot-title">Forgot Password?</p>
    </div>
    <!--begin::Form-->
    <form class="form" #myForm="ngForm" autocomplete="off">
      <div class="form-group mb-0">
        <mat-form-field>
          <mat-label>Enter OTP</mat-label>
          <input matInput type="number" [min]="0" [(ngModel)]="otp" name="otp" required class="form-control" #otp1 placeholder="Enter OTP" autocomplete="off" />
          <mat-error *ngIf="formSubmitted && otp1.invalid">
            <strong>OTP is required</strong>
          </mat-error>
        </mat-form-field>

        <div class="d-flex flex-wrap justify-content-end align-items-right m-0">
          <a href="javascript:(0);" (click)="resendOTP()" class="text-dark-50 text-hover-primary my-3 mr-2">Resend OTP</a>
        </div>

      </div>

      <!--Action-->
      <div class="form-group d-flex flex-wrap justify-content-center align-items-center">
        <button id="kt_login_signin_submit" (click)="forgotPassSubmit()" [ngClass]="{
            'spinner spinner-right spinner-md spinner-light': loading
          }" class="btn btn-primary font-weight-bold no-border-radius">Reset Password</button>
      </div>
    </form>
  </div>
  </ng-container>

  <!-- set-new-password page -->
  <ng-container *ngIf="!isForgotPassPage">
    <!--begin::Signin-->
  <div class="login-form login-signin">
    <div class="text-center mb-10 mb-lg-20">
      <h3 class="font-size-h1">Set password</h3>
    </div>

    <!--begin::Form-->
    <form class="form" #myForm="ngForm" autocomplete="off">
      <div class="form-group mb-0">
        <mat-form-field>
          <mat-label>New password</mat-label>
          <input matInput type="password" #pass="ngModel" [(ngModel)]="password"
          name="password" required placeholder="New password" autocomplete="off" />
          <mat-error *ngIf="formSubmitted && pass.invalid">
            <strong>Password is required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Retype password</mat-label>
          <input matInput type="password" required [(ngModel)]="retypePass"
          name="retype-password" placeholder="Retype password" #retypePass1="ngModel" name="retype-password" autocomplete="off" />
          <mat-error *ngIf="formSubmitted && retypePass1.invalid">
            <strong>Retype-password is required</strong>
          </mat-error>
          
        </mat-form-field>
        <span class="text-danger" *ngIf="formSubmitted && retypePass1.valid && (password !== retypePass)">
          <strong>Password doesn't match.</strong>
        </span>

        <div class="d-flex flex-wrap justify-content-end align-items-right m-0">
          <a href="javascript:(0);" (click)="resendOTP()" class="text-dark-50 text-hover-primary my-3 mr-2">Resend OTP</a>
        </div>

      </div>

      <!--Action-->
      <div class="form-group d-flex flex-wrap justify-content-center align-items-center">
        <button id="kt_login_signin_submit" (click)="resetPasswordSubmit()" [ngClass]="{
            'spinner spinner-right spinner-md spinner-light': loading
          }" class="btn btn-primary font-weight-bold no-border-radius">Reset Password</button>
      </div>
    </form>
  </div>
  </ng-container>
</div>
