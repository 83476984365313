<div class="row classContainer" autocomplete="off">
  <h3>Add Role</h3>
  <div class="row" style="width: 100%;">
    <div class="col-md-5 col-sm-5 col-xs-12">
      <form [formGroup]="roleForm" (ngSubmit)="addRole()">
        <div class="form-group">
          <label>Display Name*</label>
          <input type="text" name="name" id="" class="form-control" formControlName="display_name">
          <mat-error *ngIf="roleForm.get('display_name').hasError('required')">display name is required </mat-error>
        </div>
        <div class="form-group">
          <label>Role Type*</label>
          <input type="text" name="roletype" id="" class="form-control" formControlName="role_name"
            (blur)="checkDisplayNameValidation($event.target.value)">
          <mat-error *ngIf="roleForm.get('role_name').hasError('required')">Role Type is required </mat-error>
          <span class="text-danger" *ngIf="existRole">
            These role type is already exist
          </span>
        </div>
        <!-- <div class="form-group">
          <label>Branch</label>
          <select class="form-control" formControlName="branch"  id=branch>
            <option selected value>--Select an option--</option>
            <option *ngFor="let branch of branches" [value]="branch.Name">
              {{branch.Name}}
            </option>
          </select>
        </div> -->
        <div class="form-group">
          <label>Description</label>
          <textarea name="description" id="" cols="30" rows="5" class="form-control"
            formControlName="description"></textarea>
        </div>
        <div class="form-group" formGroupName="privilege">
          <!-- <div class="row">
            <div class="switch-div col-6">
              <label>Add Class</label>
              <div class="label-div">
                <label class="switch">
                  <input type="checkbox" formControlName="add_class">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div class="switch-div col-6">
              <label>Add Board</label>
              <div class="label-div">
                <label class="switch">
                  <input type="checkbox" formControlName="add_board">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div> -->
          <!-- <div class="row">
            <div class="switch-div col-6">
              <label>Add Role</label>
              <div class="label-div">
                <label class="switch">
                  <input type="checkbox" formControlName="create_role">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div class="switch-div col-6">
              <label>Assign role</label>
              <div class="label-div">
                <label class="switch">
                  <input type="checkbox" formControlName="assign_role">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div> -->
          <div class="row">
            <div class="switch-div col-6">
              <label>Create Question</label>
              <div class="label-div">
                <label class="switch">
                  <input type="checkbox" formControlName="create_question">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div class="switch-div col-6">
              <label>Create Question Paper</label>
              <div class="label-div">
                <label class="switch">
                  <input type="checkbox" formControlName="create_question_paper">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="switch-div col-6">
              <label>View Question Paper</label>
              <div class="label-div">
                <label class="switch">
                  <input type="checkbox" formControlName="view_question_paper">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div class="switch-div col-6">
              <label>Add Chapters</label>
              <div class="label-div">
                <label class="switch">
                  <input type="checkbox" formControlName="add_chapter">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <!-- <div class="row">
            <div class="switch-div col-6">
              <label>Add Subject</label>
              <div class="label-div">
                <label class="switch">
                  <input type="checkbox" formControlName="add_subject">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div class="switch-div col-6">
              <div class="switch-div col-6">
                <label>Add Syllabus</label>
                <div class="label-div">
                  <label class="switch">
                    <input type="checkbox" formControlName="add_syllubus">
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>

            </div>
          </div> -->
          <div class="row">
            <div class="switch-div col-6">
              <label>Add Topics</label>
              <div class="label-div">
                <label class="switch">
                  <input type="checkbox" formControlName="add_topic">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div class="switch-div col-6">
              <label>Add Learning Outcome</label>
              <div class="label-div">
                <label class="switch">
                  <input type="checkbox" formControlName="add_learning_outcome">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="switch-div col-6">
              <label>Add Question Category</label>
              <div class="label-div">
                <label class="switch">
                  <input type="checkbox" formControlName="add_question_category">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div class="switch-div col-6">
              <label>Add Exam Types</label>
              <div class="label-div">
                <label class="switch">
                  <input type="checkbox" formControlName="add_exam_types">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="switch-div col-6">
              <label>View School</label>
              <div class="label-div">
                <label class="switch">
                  <input type="checkbox" formControlName="view_school">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div class="switch-div col-6">
              <label>View Student</label>
              <div class="label-div">
                <label class="switch">
                  <input type="checkbox" formControlName="view_student">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="switch-div col-6">
              <label>view Management</label>
              <div class="label-div">
                <label class="switch">
                  <input type="checkbox" formControlName="view_management">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div class="switch-div col-6">
              <label>view Question</label>
              <div class="label-div">
                <label class="switch">
                  <input type="checkbox" formControlName="view_question">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="switch-div col-6">
              <label>view teacher</label>
              <div class="label-div">
                <label class="switch">
                  <input type="checkbox" formControlName="view_teacher">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div class="switch-div col-6">
              <label>view principal</label>
              <div class="label-div">
                <label class="switch">
                  <input type="checkbox" formControlName="view_principle">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="switch-div col-6">
              <label>Add Q&A</label>
              <div class="label-div">
                <label class="switch">
                  <input type="checkbox" formControlName="add_qa">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div class="switch-div col-6">
              <label>Add Assessment</label>
              <div class="label-div">
                <label class="switch">
                  <input type="checkbox" formControlName="add_assessment">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div *ngIf="!isOwner;else myFlag;"></div>
          <ng-template #myFlag>
            <div class="row">
              <div class="switch-div col-6">
                <label>Create Management</label>
                <div class="label-div">
                  <label class="switch">
                    <input type="checkbox" formControlName="create_management">
                    <span class="slider round"></span>
                  </label>
                </div>

              </div>
              <div class="switch-div col-6">
                <label>Create Student</label>
                <div class="label-div">
                  <label class="switch">
                    <input type="checkbox" formControlName="create_student">
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="switch-div col-6">
                <label>Create Teacher</label>
                <div class="label-div">
                  <label class="switch">
                    <input type="checkbox" formControlName="create_teacher">
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
              <div class="switch-div col-6">
                <label>Create Principle</label>
                <div class="label-div">
                  <label class="switch">
                    <input type="checkbox" formControlName="create_principle">
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
            <!-- <div class="row">
              <div class="switch-div col-6">
                <label>Add section</label>
                <div class="label-div">
                  <label class="switch">
                    <input type="checkbox" formControlName="add_section">
                    <span class="slider round"></span>
                  </label>
                </div>

              </div>
              <div class="switch-div col-6">
                <label>Mapping </label>
                <div class="label-div">
                  <label class="switch">
                    <input type="checkbox" formControlName="add_mapping">
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </div> -->
          </ng-template>

        </div>

        <div class="form-group import-btn ">
          <button class="btn btn-primary" [disabled]="!roleForm.valid || existRole">Add Role</button>
          <input type="reset" id="reset" class="btn btn-primary" value="Reset" hidden>
        </div>

      </form>
    </div>
    <div class="col-md-7 col-sm-7 col-xs-12 allroles">



      <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput placeholder="Search a Role" (keyup)="applyFilter($event)" #input>
      </mat-form-field>

      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>

          <!-- display name Column -->
          <ng-container matColumnDef="displayName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Display Name </th>
            <td mat-cell *matCellDef="let row"> {{row.display_name}} </td>
          </ng-container>

          <!-- description Column -->
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
            <td mat-cell *matCellDef="let row"> {{row.description}} </td>
          </ng-container>

          <!-- roleName Column -->
          <ng-container matColumnDef="roleName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Role Name </th>
            <td mat-cell *matCellDef="let row"> {{row.role_name}} </td>
          </ng-container>


          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
            <td mat-cell *matCellDef="let row">
              <button class="btn btn-primary" (click)="updateRole(row)">
                <mat-icon aria-hidden="false" aria-label="Update">edit</mat-icon>
              </button>
              <button class="btn btn-primary" (click)="deleteRole(row)">
                <mat-icon aria-hidden="false" aria-label="Delete">delete</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
          </tr>
        </table>

        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>





      <h3 *ngIf="!rolesLoaded" style="text-align: center; margin-top: 40px;"><strong>Loading roles please wait</strong>
      </h3>
    </div>
  </div>
</div>
