import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-edit-section-modal',
  templateUrl: './edit-section-modal.component.html',
  styleUrls: ['./edit-section-modal.component.scss']
})
export class EditSectionModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
