<div>
  <h3>Payments</h3>
  <mat-form-field appearance="standard" style="width: 100%;">
    <mat-label>Filter</mat-label>
    <input (keyup)="applyFilter($event)" matInput placeholder="Search columns" #input>
  </mat-form-field>
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSources" style="width: 100%;">

      <!-- Position Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> Order Id </th>
        <td mat-cell *matCellDef="let element"> {{element.order_id}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef> Amount </th>
        <td mat-cell *matCellDef="let element"> {{element.amount}} </td>
      </ng-container>

      <!-- Due Date -->
      <ng-container matColumnDef="duedate">
        <th mat-header-cell *matHeaderCellDef> Bill of </th>
        <td mat-cell *matCellDef="let element"> {{element.created_at}} </td>
      </ng-container>

      <!-- Payment Column -->
      <ng-container matColumnDef="payment">
        <th mat-header-cell *matHeaderCellDef> Payment </th>
        <td mat-cell *matCellDef="let element">
          <button *ngIf="element.status === 'Paid'" mat-raised-button color="primary"
            style="background-color: #009879;">
            <mat-icon>check</mat-icon> Paid
          </button>

          <button mat-raised-button color="primary" [disabled]="!element.canPay" *ngIf="element.status !== 'Paid'" style="background-color: #cb1939;"
            (click)="payNow(element.order_id)">
            <mat-icon>credit_card</mat-icon> Pay Now
          </button>

        </td>
      </ng-container>

        <!-- Invoice  -->
        <ng-container matColumnDef="invoice">
          <th mat-header-cell *matHeaderCellDef> Invoice </th>
          <td mat-cell *matCellDef="let element"> <button mat-raised-button
              color="primary" (click)="invoiceDialog(element)" style="background-color: #009879;">
              <mat-icon>receipt_long</mat-icon> Print Invoice
            </button> </td>
        </ng-container>

      <!-- Receipt  -->
      <ng-container matColumnDef="receipt">
        <th mat-header-cell *matHeaderCellDef> Receipts </th>
        <td mat-cell *matCellDef="let element"> <button [disabled]="element.status !== 'Paid'" mat-raised-button
            color="primary" (click)="invoiceDialog(element,true)" style="background-color: #009879;">
            <mat-icon>receipt_long</mat-icon> Print Receipt
          </button> </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef=" displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator class="mat-paginator-sticky" #myPaginator [pageSize]="10" [pageSizeOptions]="[5, 10, 15]"
      showFirstLastButtons>
    </mat-paginator>
  </div>
</div>