<div>
  <h3>All Admissions</h3>
  <br />
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource">

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <!-- Phone Column -->
      <ng-container matColumnDef="phoneno">
        <th mat-header-cell *matHeaderCellDef> Phone Number </th>
        <td mat-cell *matCellDef="let element"> {{element.username}} </td>
      </ng-container>

      <!-- Class Column -->
      <ng-container matColumnDef="class">
        <th mat-header-cell *matHeaderCellDef> Class </th>
        <td mat-cell *matCellDef="let element"> {{element.class}} </td>
      </ng-container>

      <!-- Section Column -->
      <ng-container matColumnDef="section">
        <th mat-header-cell *matHeaderCellDef> Section </th>
        <td mat-cell *matCellDef="let element"> {{element.section}} </td>
      </ng-container>

      <!-- Aprrove Column -->
      <ng-container matColumnDef="approve" style="width: 10%;">
        <th mat-header-cell *matHeaderCellDef> Approve </th>
        <td mat-cell *matCellDef="let element"> <button mat-mini-fab color="primary">
            <mat-icon>done</mat-icon>
          </button></td>
      </ng-container>

      <!-- Cancel Column -->
      <ng-container matColumnDef="reject">
        <th mat-header-cell *matHeaderCellDef> Reject </th>
        <td mat-cell *matCellDef="let element"> <button mat-mini-fab color="primary">
            <mat-icon>close</mat-icon>
          </button></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
</div>