<div class="bg">
    <h2>Register Management</h2>
    <div>
        <input type="file" style="display:none" #fileUpload (change)="onFileUpload($event)">
        <div class="userPicDiv" (click)="fileUpload.click()">
            <span *ngIf="!filePreview">
                <img src="/assets/media/growon/logos/profilePicLogo.png" alt="">
                <p>Click to add picture</p>
            </span>
            <span *ngIf="filePreview">
                <img [src]="filePreview" alt="">
            </span>
        </div>
    </div>
    <form [formGroup]="managementForm" autocomplete="off">
        <mat-accordion>
            <mat-expansion-panel expanded="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Personal Details
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <!-- Name -->
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <mat-form-field appearance="fill">
                        <mat-label>Name*</mat-label>
                        <input matInput placeholder="Name" formControlName="name">
                        <mat-error *ngIf="managementForm.get('name').hasError('required')">Management name is required
                        </mat-error>
                        <mat-error *ngIf="managementForm.get('name').hasError('maxlength')">You can only enter maximum
                            of 50 character </mat-error>
                    </mat-form-field>
                </div>
                <!-- Gender -->
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <mat-radio-group formControlName="gender" color="primary">
                        <mat-label>Gender*</mat-label>
                        <mat-radio-button class="example-radio-button" *ngFor="let gend of gender"
                            [value]="gend">{{gend}}
                        </mat-radio-button>
                        <mat-error *ngIf="managementForm.get('gender').hasError('required')"> Gender is required
                        </mat-error>
                    </mat-radio-group>
                </div>
                <br />
                <div class="row">
                    <!-- <div class="col-md-4 col-sm-4 col-xs-12"> -->
                    <!--  -->
                    <!-- <mat-form-field appearance="fill">
                    <mat-label>Password*</mat-label>
                    <input type="text" matInput placeholder="Password" formControlName="password" >
                    <mat-error *ngIf="managementForm.get('password').hasError('required')"> password is required
                    </mat-error>
                </mat-form-field> -->
                    <!-- </div> -->
                    <div class="col-md-3 col-sm-3 col-xs-12">
                        <mat-form-field appearance="fill">
                            <mat-label>Branch*</mat-label>
                            <mat-select placeholder="Branch" formControlName="branch">
                                <mat-option *ngFor="let branch of branches" [value]="branch._id">
                                    {{branch.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="managementForm.get('branch').hasError('required')"> please select the
                                branch
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <!-- Contact -->
                    <div class="col-md-3 col-sm-3 col-xs-12">
                        <mat-form-field appearance="fill">
                            <mat-label>Contact Number*</mat-label>
                            <input type="number" matInput placeholder="Contact Number"
                                (blur)="checkAlreadyExist($event.target.value)" formControlName="contact">
                            <div class="text-danger" *ngIf="userExistFlag">Mobile number is already exist.</div>
                            <mat-error *ngIf="managementForm.get('contact').hasError('required')">Management contact
                                required
                            </mat-error>
                            <mat-error *ngIf="managementForm.get('contact').hasError('maxlength')"> you can enter
                                maximum of
                                15
                                numbers </mat-error>
                            <mat-error *ngIf="managementForm.get('contact').hasError('minlength')"> You should enter
                                minimum
                                of
                                10 numbers</mat-error>
                            <mat-error *ngIf="managementForm.get('contact').hasError('pattern')"> Contact number can
                                only
                                have
                                numbers</mat-error>
                        </mat-form-field>
                    </div>
                    <!-- DOB -->
                    <div class="col-md-3 col-sm-3 col-xs-12">
                        <mat-form-field appearance="fill">
                            <mat-label>Date of Birth (Please select from calender)</mat-label>
                            <input matInput [matDatepicker]="picker2" formControlName="dob">
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>

                        </mat-form-field>
                    </div>
                    <!-- Email -->
                    <div class="col-md-3 col-sm-3 col-xs-12">
                        <mat-form-field appearance="fill">
                            <mat-label>Email</mat-label>
                            <input type="email" matInput placeholder="Email" formControlName="email">
                            <mat-error *ngIf="managementForm.get('email').hasError('pattern')"> Invalid email format
                            </mat-error>
                        </mat-form-field>
                    </div>

                </div>
                <div class="row">
                    <!-- Qualification  -->
                    <div class="col-md-3 col-sm-3 col-xs-12">
                        <mat-form-field appearance="fill">
                            <mat-label>Qualification</mat-label>
                            <mat-select placeholder="Qualification" formControlName="qualification">
                                <mat-option *ngFor="let qua of qualification" [value]="qua">
                                    {{qua}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <!-- Aadhar card no -->
                    <div class="col-md-3 col-sm-3 col-xs-12">
                        <mat-form-field appearance="fill">
                            <mat-label>Aadhaar Card no</mat-label>
                            <input type="tel" matInput placeholder="Aadhaar Card no" formControlName="aadhaarNo">
                            <mat-error *ngIf="managementForm.get('aadhaarNo').hasError('maxlength')">you have reached
                                maximum
                                limit
                                of
                                characters </mat-error>
                            <mat-error *ngIf="managementForm.get('aadhaarNo').hasError('pattern')"> aadhaarNo can only
                                have
                                numbers
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <!-- Blood group -->
                    <div class="col-md-3 col-sm-3 col-xs-12">
                        <!-- Blood group -->
                        <mat-form-field appearance="fill">
                            <mat-label>Blood Group</mat-label>
                          <mat-select placeholder="Blood Group" formControlName="bloodGroup">
                            <mat-option value="A+"> A+ </mat-option>
                            <mat-option value="A-"> A- </mat-option>
                            <mat-option value="B+"> B+ </mat-option>
                            <mat-option value="B-"> B- </mat-option>
                            <mat-option value="O+"> O+ </mat-option>
                            <mat-option value="0-"> O- </mat-option>
                            <mat-option value="AB+"> AB+ </mat-option>
                            <mat-option value="AB-"> AB- </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    <!-- Mother Tongue -->
                    <div class="col-md-3 col-sm-3 col-xs-12">
                        <mat-form-field appearance="fill">
                            <mat-label>Mother Tongue</mat-label>
                            <input matInput placeholder="Mother Tongue" formControlName="motherTongue">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <!-- Marital status -->
                    <div class="col-md-3 col-sm-3 col-xs-12">
                        <mat-form-field appearance="fill">
                            <mat-label>Marital status</mat-label>
                            <mat-select placeholder="Marital status" formControlName="maritalStatus">
                                <mat-option *ngFor="let mode of maritalStatus" [value]="mode">
                                    {{mode}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <!-- Religion -->
                    <div class="col-md-3 col-sm-3 col-xs-12">
                        <mat-form-field appearance="fill">
                            <mat-label>Religion</mat-label>
                            <input matInput placeholder="Religion" formControlName="religion">
                        </mat-form-field>
                    </div>
                    <!-- Cast -->
                    <div class="col-md-3 col-sm-3 col-xs-12">
                        <mat-form-field appearance="fill">
                            <mat-label>Caste</mat-label>
                            <input matInput placeholder="Cast" formControlName="caste">
                        </mat-form-field>
                    </div>
                </div>

                <hr />
                <h3>Address</h3>
                <br />
                <!-- Address -->
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <mat-form-field appearance="fill">
                        <mat-label>Address</mat-label>
                        <textarea matInput placeholder="Address" formControlName="address"></textarea>
                        <mat-error *ngIf="managementForm.get('address').hasError('maxlength')">You can only enter
                            maximum
                            of 150 character </mat-error>
                    </mat-form-field>
                </div>
                <!-- CSCP -->
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="row">
                        <!-- Country -->
                        <div class="col-md-3 col-sm-3 col-xs-12">
                            <mat-form-field appearance="fill">
                                <mat-label>Select Country</mat-label>
                                <mat-select placeholder="Country" formControlName="country"
                                    (selectionChange)="getStates()">
                                    <mat-option *ngFor="let country of countries" [value]="country._id">
                                        {{country.country_name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                        </div>
                        <!-- State -->
                        <div class="col-md-3 col-sm-3 col-xs-12">
                            <mat-form-field appearance="fill">
                                <mat-label>Select State</mat-label>
                                <mat-select placeholder="State" formControlName="state" (selectionChange)="getCities()">
                                    <mat-option *ngFor="let state of states" [value]="state._id">
                                        {{state.state_name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <!-- City -->
                        <div class="col-md-3 col-sm-3 col-xs-12">
                            <mat-form-field appearance="fill">
                                <mat-label>Select City</mat-label>
                                <mat-select placeholder="City" formControlName="city">
                                    <mat-option *ngFor="let city of cities" [value]="city._id">
                                        {{city.city_name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <!-- Pincode -->
                        <div class="col-md-3 col-sm-3 col-xs-12">
                            <mat-form-field appearance="fill">
                                <mat-label>Pincode</mat-label>
                                <input matInput type="tel" placeholder="pincode" formControlName="pinCode">
                                <mat-error *ngIf="managementForm.get('pinCode').hasError('maxlength')">You can only
                                    enter
                                    maximum
                                    of 10 number </mat-error>

                                <mat-error *ngIf="managementForm.get('pinCode').hasError('pattern')"> pincode can only
                                    have
                                    numbers
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Authorization Details
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <br />
                <div class="row">
                    <div style="width:25%;margin-left: 15px;">
                        <mat-label>Event Authorization </mat-label>
                        <mat-radio-group [(ngModel)]="isAuthorized" formControlName="authorized">
                            <mat-radio-button class="example-radio-button" *ngFor="let auth of authorized"
                                [value]="auth == 'Yes' ? true : false">{{auth}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div>
                        <mat-label>Announcment Message Authorization</mat-label>
                        <mat-radio-group [(ngModel)]="mAuthorized" formControlName="mauthorized">
                            <mat-radio-button class="example-radio-button" *ngFor="let auth of mauthorized"
                                [value]="auth == 'Yes' ? true : false">{{auth}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>

                </div>
            </mat-expansion-panel>
        </mat-accordion>

        <br />
        <div class="import-btn">
            <button class="btn btn-primary" [disabled]="!managementForm.valid"
                (click)="createManagement()">Submit</button>
        </div>
    </form>
</div>