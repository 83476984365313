<div>
  <button class="close close-btn btn-foucs-none" aria-label="Close" (click)="activeModal.close('success')">
    <span aria-hidden="true" class="close-icon">&times;</span>
  </button>
  <div style="display: flex; justify-content: end" class="prof-img">
    <input type="file" style="display: none" #fileUpload (change)="onFileUpload($event)" />
  </div>
  <div class="userPicDiv" (click)="fileUpload.click()">
    <span *ngIf="!filePreview">
      <img src="/assets/media/growon/logos/profilePicLogo.png" alt="" />
      <p>Click to add picture</p>
    </span>
    <span *ngIf="filePreview">
      <img [src]="filePreview" alt="" />
    </span>
  </div>
</div>
<div class="classcontainer">
  <div class="modal-header">
    <h4 class="modal-title" class=".col-sm-5 .col-sm-offset-2 .col-md-6 .col-md-offset-0">
      Update Student
    </h4>
  </div>

  <div class="modal-body px-0" *ngIf="updateStudentForm.value">
    <div style="overflow-y: hidden; height: calc(100vh - 15rem)">
      <div class="px-2" style="overflow-y: auto; height: 100%">
        <form [formGroup]="updateStudentForm" autocomplete="off">
          <mat-accordion>
            <mat-expansion-panel expanded="true">
              <mat-expansion-panel-header>
                <mat-panel-title> Personal Details </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <mat-form-field appearance="fill">
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="name" #firstname="matInput" required />

                    <mat-error *ngIf="updateStudentForm.get('name').hasError('required')">Student name is required
                    </mat-error>
                    <mat-error *ngIf="
                        updateStudentForm.get('name').hasError('maxlength')
                      ">You can only enter maximum of 50 character
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <!-- Username -->
                  <mat-form-field appearance="fill">
                    <mat-label>Username</mat-label>
                    <input matInput formControlName="username" readonly />
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 col-sm-4 col-xs-12">
                  <!-- Gender -->
                  <mat-radio-group formControlName="gender" color="primary">
                    <mat-label>Gender*</mat-label>
                    <mat-radio-button class="example-radio-button" *ngFor="let gend of genders" [value]="gend">
                      {{ gend }}
                    </mat-radio-button>
                  </mat-radio-group>
                  <mat-error *ngIf="updateStudentForm.get('gender').hasError('required')">gender is required
                  </mat-error>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-12">
                  <mat-form-field appearance="fill">
                    <mat-label>Religion</mat-label>
                    <input matInput placeholder="Religion" formControlName="religion" />
                  </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-12">
                  <!-- Caste -->
                  <mat-form-field appearance="fill">
                    <mat-label>Caste</mat-label>
                    <input matInput placeholder="Caste" formControlName="caste" />
                  </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-12">
                  <!-- Mother Tongue -->
                  <mat-form-field appearance="fill">
                    <mat-label>Mother Tongue</mat-label>
                    <input matInput placeholder="Mother Tongue" formControlName="motherTongue" />
                  </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-12">
                  <!-- Blood Group -->
                  <mat-form-field appearance="fill">
                    <mat-label>Blood Group</mat-label>
                    <mat-select placeholder="Blood Group" formControlName="bloodGroup">
                      <mat-option value="A+"> A+ </mat-option>
                      <mat-option value="A-"> A- </mat-option>
                      <mat-option value="B+"> B+ </mat-option>
                      <mat-option value="B-"> B- </mat-option>
                      <mat-option value="O+"> O+ </mat-option>
                      <mat-option value="0-"> O- </mat-option>
                      <mat-option value="AB+"> AB+ </mat-option>
                      <mat-option value="AB-"> AB- </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12">
                  <!-- Address -->
                  <mat-form-field appearance="fill">
                    <mat-label>Address</mat-label>
                    <textarea matInput placeholder="Address" formControlName="address"></textarea>

                    <mat-error *ngIf="
                        updateStudentForm.get('address').hasError('required')
                      ">Address is required
                    </mat-error>
                    <mat-error *ngIf="
                        updateStudentForm.get('address').hasError('maxlength')
                      ">You can only enter maximum of 150 character
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-12">
                  <!-- Country -->
                  <mat-form-field appearance="fill">
                    <mat-label>Select Country</mat-label>
                    <mat-select placeholder="Country" formControlName="country" (selectionChange)="getStates()">
                      <mat-option *ngFor="let country of countries" [value]="country._id">
                        {{ country.country_name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-12">
                  <!-- State -->
                  <mat-form-field appearance="fill">
                    <mat-label>Select State</mat-label>
                    <mat-select placeholder="State" formControlName="state" (selectionChange)="getCities()">
                      <mat-option *ngFor="let state of states" [value]="state._id">
                        {{ state.state_name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-12">
                  <mat-form-field appearance="fill">
                    <mat-label>Select City</mat-label>
                    <mat-select placeholder="City" formControlName="city">
                      <mat-option *ngFor="let city of cities" [value]="city._id">
                        {{ city.city_name }}
                      </mat-option>
                    </mat-select>
                    <!-- <mat-error *ngIf="updateStudentForm.get('city').hasError('required')">Please select a
                                                  city </mat-error> -->

                  </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-12">
                  <mat-form-field appearance="fill">
                    <mat-label>Pincode</mat-label>
                    <input matInput type="tel" placeholder="pincode" formControlName="pinCode" />

                    <!-- <mat-error *ngIf="updateStudentForm.get('pinCode').hasError('required')">pincode is
                                                  required </mat-error> -->
                    <mat-error *ngIf="
                        updateStudentForm.get('pinCode').hasError('maxlength')
                      ">You can only enter maximum of 10 number
                    </mat-error>

                    <mat-error *ngIf="
                        updateStudentForm.get('pinCode').hasError('pattern')
                      ">
                      pincode can only have numbers
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-12">
                  <!-- Mode of Transportation -->
                  <mat-form-field appearance="fill">
                    <mat-label>Mode of Transportation</mat-label>
                    <mat-select placeholder="Mode of Transportation" formControlName="transportation">
                      <mat-option *ngFor="let mode of transportationMode" [value]="mode">
                        {{ mode }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-12">
                  <!-- Medical Conditions (if Any) -->
                  <mat-form-field appearance="fill">
                    <mat-label>Medical Conditions (if Any)</mat-label>
                    <mat-select placeholder="Medical Conditions (if Any)" formControlName="medicalConditions">
                      <mat-option *ngFor="let condition of medicalConditions" [value]="condition">
                        {{ condition }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12">
                  <!-- Does the Child wear Glasses -->
                  <mat-radio-group formControlName="glasses" color="primary">
                    <mat-label>Does the Child wear Glasses</mat-label>
                    <mat-radio-button class="example-radio-button" *ngFor="let bo of boolean" [value]="bo">
                      {{ bo }}
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>

              <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-12">
                  <!-- Email -->
                  <mat-form-field appearance="fill">
                    <mat-label>Email</mat-label>
                    <input type="email" matInput placeholder="Email" formControlName="email" />
                    <mat-error *ngIf="updateStudentForm.get('email').hasError('pattern')">Invalid email format
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-12">
                  <!-- DOB -->
                  <mat-form-field appearance="fill">
                    <mat-label>Date of Birth(MM/DD/YYYY)</mat-label>
                    <input matInput [matDatepicker]="picker2" formControlName="dob" />
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-12">
                  <!-- Aadhar card no -->
                  <mat-form-field appearance="fill">
                    <mat-label>Aadhaar Card no</mat-label>
                    <input type="tel" matInput placeholder="Aadhaar Card no" formControlName="aadhaarNo" />
                    <mat-error *ngIf="
                        updateStudentForm.get('aadhaarNo').hasError('maxlength')
                      ">you have reached maximum limit of characters
                    </mat-error>
                    <mat-error *ngIf="
                        updateStudentForm.get('aadhaarNo').hasError('pattern')
                      ">
                      aadhaarNo can only have numbers
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
                <div class="row">
                  <div class="col-md-12 col-sm-12 col-xs-12">
                      <mat-label>Birth Certificate</mat-label>
                      <div *ngIf="!birthDocPreview">
                        <input #fileInput type="file" (change)="onFileInput($event,'birthCertificate')">
                      </div>
                      <div *ngIf="birthDocPreview">
                        <a class="btn btn-primary" style="color:#000" 
                         [href]="birthDocPreview" >Download</a>
                         <button style="margin: 0px 10px;" (click)="removeDOC('birthCertificate')" mat-button
                         class="btn btn-primary">
                         <mat-icon style="color: #000;">close</mat-icon>
                       </button>
                      </div>
                    </div>
                </div>
                <br/>
                <div class="row">
                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <mat-label>Aadhaar Card</mat-label>
                    <div *ngIf="!aadhaarDocPreview">
                      <input #fileInput type="file" (change)="onFileInput($event,'aadharCard')">
                    </div>
                    <div *ngIf="aadhaarDocPreview">
                      <a class="btn btn-primary" style="color:#000" [href]="aadhaarDocPreview" >Download</a>
                      <button style="margin: 0px 10px;" (click)="removeDOC('aadharCard')" mat-button
                         class="btn btn-primary">
                         <mat-icon style="color: #000;">close</mat-icon>
                       </button>
                    </div>
                  </div>
                </div>
                <br/>
                <div class="row">
                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <mat-label>Income Certificate</mat-label>
                    <div *ngIf="!incomeDocPreview">
                      <input #fileInput type="file" (change)="onFileInput($event,'incomeCertificate')">
                    </div>
                    <div *ngIf="incomeDocPreview">
                      <a class="btn btn-primary" style="color:#000" [href]="incomeDocPreview" >Download</a>
                      <button style="margin: 0px 10px;" (click)="removeDOC('incomeCertificate')" mat-button
                         class="btn btn-primary">
                         <mat-icon style="color: #000;">close</mat-icon>
                       </button>
                    </div>
                  </div>
                </div>
                <br/>
              <!-- </div> -->
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> Academic Deatails </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-12">
                  <mat-form-field appearance="fill">
                    <mat-label>Class</mat-label>
                    <mat-select (selectionChange)="primaryClassChange($event)" placeholder="Class"
                      formControlName="class" required>
                      <mat-option *ngFor="let class of class" [value]="class.classId">
                        {{ class.className }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="updateStudentForm.get('class').hasError('required')">class is required
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-12">
                  <!-- Student class -->
                  <mat-form-field appearance="fill">
                    <mat-label>Section</mat-label>
                    <mat-select placeholder="Section/Division" formControlName="section">
                      <!-- <ng-option [value]="">Section/Division</ng-option> -->
                      <mat-option *ngFor="let section of sections" [value]="section._id">{{ section.name }}
                      </mat-option>
                    </mat-select>
                    <!-- <mat-error *ngIf="studentForm.get('section').hasError('required')">required </mat-error> -->

                  </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-3">
                  <mat-form-field appearance="fill">
                    <mat-label>Branch</mat-label>
                    <mat-select placeholder="Branch" formControlName="branch_id" required onchange="alert(branch._id)">
                      <mat-option *ngFor="let branch of branches" [value]="branch._id">
                        {{ branch.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="
                      updateStudentForm.get('branch_id').hasError('required')
                    ">branch is required
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-12">
                  <!-- Admission Number -->
                  <mat-form-field appearance="fill">
                    <mat-label>Admission Number</mat-label>
                    <input type="text" matInput placeholder="Admission Number" formControlName="admissionno" />
                  </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-12">
                  <!-- STS no -->
                  <mat-form-field appearance="fill">
                    <mat-label>STS no</mat-label>
                    <input matInput placeholder="STS no" formControlName="STSNo" />
                  </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-12">
                  <!-- RTE Student -->
                  <mat-form-field appearance="fill">
                    <mat-label>RTE Student</mat-label>
                    <input matInput placeholder="RTE Student" formControlName="RTEStudent" />
                  </mat-form-field>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> Parent/Guardian Details </mat-panel-title>
              </mat-expansion-panel-header>
              <hr />
              <h1>Parent/Guardian</h1>
              <div class="row">
                <div class="col-md-4 col-sm-4 col-xs-4">
                  <mat-form-field appearance="fill">
                    <mat-label>Primary Parent / Guardian</mat-label>
                    <mat-select placeholder="primaryParent" formControlName="primaryParent" required>
                      <mat-option *ngFor="let primary of primaryParent" [value]="primary.value">
                        {{ primary.name }}
                      </mat-option>
                    </mat-select>

                    <mat-error *ngIf="
                        updateStudentForm
                          .get('primaryParent')
                          .hasError('required')
                      ">Primary parent is required
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-8 col-sm-8 col-xs-8" style="display: flex; align-items: flex-start">
                  <h4>
                    Note: The mobile number of this parent/guardian will be user
                    for parent login
                  </h4>
                </div>
              </div>
              <!-- Father -->
              <h1>1. Father</h1>
              <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-12">
                  <mat-form-field appearance="fill">
                    <mat-label>Father Name</mat-label>
                    <input matInput placeholder="Father Name" formControlName="father_name" />
                    <mat-error *ngIf="
                        updateStudentForm
                          .get('father_name')
                          .hasError('required')
                      ">Father name is required
                    </mat-error>
                    <mat-error *ngIf="
                        updateStudentForm
                          .get('father_name')
                          .hasError('maxlength')
                      ">You can only enter maximum of 50 character
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-12">
                  <mat-form-field appearance="fill">
                    <mat-label>Father Contact</mat-label>
                    <input type="number" matInput placeholder="Father Contact" formControlName="f_contact_number"
                      (blur)="checkParentValidation('father')" />

                    <!-- [readonly]="this.students.parent_id.guardian=='father'" -->
                  </mat-form-field>
                  <span class="text-danger" *ngIf="parentNumberExist[0]">
                    Mobile number is already in use.
                  </span>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-12">
                  <mat-form-field appearance="fill">
                    <mat-label>Father Email</mat-label>
                    <input type="email" matInput placeholder="Father Email" formControlName="f_email" />
                    <mat-error *ngIf="
                        updateStudentForm.get('f_email').hasError('pattern')
                      ">Invalid email format</mat-error>
                  </mat-form-field>
                </div>
                <!-- Qualification -->
                <div class="col-md-3 col-sm-3 col-xs-12">
                  <mat-form-field appearance="fill">
                    <mat-label>Father Qualification</mat-label>
                    <!-- <input matInput placeholder="Father Name" formControlName="fatherQualification" class="form-control"> -->
                    <mat-select placeholder="Father Qualification" formControlName="f_qualification">
                      <mat-option *ngFor="let qualification of parentQualification" [value]="qualification">
                        {{ qualification }}
                      </mat-option>
                    </mat-select>
                    <!-- <mat-error *ngIf="studentForm.get('fatherQualification').hasError('required')"> required </mat-error> -->
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <!-- Occupation -->
                <div class="col-md-12">
                  <mat-form-field appearance="fill">
                    <mat-label>Occupation</mat-label>
                    <input type="tel" matInput placeholder="Occupation" formControlName="f_occupation" />
                  </mat-form-field>
                </div>
                <!-- Aadhar -->
                <div class="col-md-12">
                  <mat-form-field appearance="fill">
                    <mat-label>Aadhaar Card no</mat-label>
                    <input type="tel" matInput placeholder="Aadhaar Card no" formControlName="f_aadhar_no" />
                    <mat-error *ngIf="
                        updateStudentForm
                          .get('f_aadhar_no')
                          .hasError('maxlength')
                      ">you have reached maximum limit of characters
                    </mat-error>
                    <mat-error *ngIf="
                        updateStudentForm.get('f_aadhar_no').hasError('pattern')
                      ">
                      aadhaarNo can only have numbers
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-12">
                  <mat-label>Father Aadhaar</mat-label>
                  <div *ngIf="!fAadhaarDocPreview">
                    <input #fileInput type="file" (change)="onFileInput($event,'fatherAadhaar')">
                  </div>
                  <div *ngIf="fAadhaarDocPreview">
                    <a class="btn btn-primary" style="color:#000" [href]="fAadhaarDocPreview" >Download</a>
                    <button style="margin: 0px 10px;" (click)="removeDOC('fatherAadhaar')" mat-button
                       class="btn btn-primary">
                       <mat-icon style="color: #000;">close</mat-icon>
                     </button>
                  </div>
                </div>
              </div>
              <!-- Language Proficiency -->
              <h1>Language Proficiency</h1>
              <div class="row" *ngFor="let count of countOfFour">
                <div class="col-md-3 col-sm-3 col-xs-3">
                  <mat-form-field appearance="fill">
                    <mat-label>Language {{ count }}</mat-label>
                    <input matInput placeholder="Language {{ count }}" formControlName="fatherLanguage{{ count }}" />
                  </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-3">
                  <mat-form-field>
                    <mat-label>Read</mat-label>
                    <mat-select placeholder="Read" formControlName="fatherLanguageRead{{ count }}">
                      <mat-option *ngFor="let boo of boolean" [value]="boo">
                        {{ boo }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-3">
                  <mat-form-field>
                    <mat-label>Write</mat-label>
                    <mat-select placeholder="Read" formControlName="fatherLanguageWrite{{ count }}">
                      <mat-option *ngFor="let boo of boolean" [value]="boo">
                        {{ boo }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-3">
                  <mat-form-field>
                    <mat-label>Speak</mat-label>
                    <mat-select placeholder="Read" formControlName="fatherLanguageSpeak{{ count }}">
                      <mat-option *ngFor="let boo of boolean" [value]="boo">
                        {{ boo }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <!-- Mother -->
              <h1>2. Mother</h1>
              <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-12">
                  <mat-form-field appearance="fill">
                    <mat-label>Mother Name</mat-label>
                    <input matInput placeholder="Mother Name" formControlName="motherName" />
                    <mat-error *ngIf="
                        updateStudentForm.get('motherName').hasError('required')
                      ">Mother name is required
                    </mat-error>
                    <mat-error *ngIf="
                        updateStudentForm
                          .get('motherName')
                          .hasError('maxlength')
                      ">You can only enter maximum of 50 character
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-12">
                  <!-- Contact -->
                  <mat-form-field appearance="fill">
                    <mat-label>Mother Contact</mat-label>
                    <input type="number" matInput placeholder="Mother Contact" formControlName="motherContact"
                      (blur)="checkParentValidation('mother')" />
                  </mat-form-field>
                  <span class="text-danger" *ngIf="parentNumberExist[1]">
                    Mobile number is already in use.
                  </span>
                </div>
                <!-- Email -->
                <div class="col-md-3 col-sm-3 col-xs-12">
                  <!-- Email -->
                  <mat-form-field appearance="fill">
                    <mat-label>Mother Email</mat-label>
                    <input type="email" matInput placeholder="Mother Email" formControlName="motherEmail" />
                    <mat-error *ngIf="
                        updateStudentForm.get('motherEmail').hasError('pattern')
                      ">Invalid email format</mat-error>
                  </mat-form-field>
                </div>
                <!-- Qualification -->
                <div class="col-md-3 col-sm-3 col-xs-12">
                  <!-- Qualification -->
                  <mat-form-field appearance="fill">
                    <mat-label>Mother Qualification</mat-label>
                    <!-- <input matInput placeholder="Mother Name" formControlName="motherQualification" class="form-control"> -->
                    <mat-select placeholder="Mother Qualification" formControlName="motherQualification">
                      <mat-option *ngFor="let qualification of parentQualification" [value]="qualification">
                        {{ qualification }}
                      </mat-option>
                    </mat-select>
                    <!-- <mat-error *ngIf="studentForm.get('motherQualification').hasError('required')">required </mat-error> -->
                  </mat-form-field>
                </div>
              </div>
              <!-- Occupation -->
              <div class="row">
                <!-- Occupation -->
                <div class="col-md-12">
                  <mat-form-field appearance="fill">
                    <mat-label>Occupation</mat-label>
                    <input type="tel" matInput placeholder="Occupation" formControlName="m_occupation" />
                  </mat-form-field>
                </div>
                <!-- Aadhar -->
                <div class="col-md-12">
                  <mat-form-field appearance="fill">
                    <mat-label>Aadhaar Card no</mat-label>
                    <input type="tel" matInput placeholder="Aadhaar Card no" formControlName="motheraadhaarNo" />
                    <mat-error *ngIf="
                        updateStudentForm
                          .get('motheraadhaarNo')
                          .hasError('maxlength')
                      ">you have reached maximum limit of characters
                    </mat-error>
                    <mat-error *ngIf="
                        updateStudentForm
                          .get('motheraadhaarNo')
                          .hasError('pattern')
                      ">
                      aadhaarNo can only have numbers
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-12">
                  <mat-label>Mother Aadhaar</mat-label>
                  <div *ngIf="!mAadhaarDocPreview">
                    <input #fileInput type="file" (change)="onFileInput($event,'motherAadhaar')">
                  </div>
                  <div *ngIf=" mAadhaarDocPreview">
                    <a class="btn btn-primary" style="color:#000" [href]="mAadhaarDocPreview" >Download</a>
                      <button style="margin: 0px 10px;" (click)="removeDOC('motherAadhaar')" mat-button
                         class="btn btn-primary">
                         <mat-icon style="color: #000;">close</mat-icon>
                       </button>
                  </div>
                </div>
              </div>
              <!-- Language Proficiency -->
              <h1>Language Proficiency</h1>
              <div class="row" *ngFor="let count of countOfFour">
                <div class="col-md-3 col-sm-3 col-xs-3">
                  <mat-form-field appearance="fill">
                    <mat-label>Language {{ count }}</mat-label>
                    <input matInput placeholder="Language {{ count }}" formControlName="motherLanguage{{ count }}" />
                  </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-3">
                  <mat-form-field>
                    <mat-label>Read</mat-label>
                    <mat-select placeholder="Read" formControlName="motherLanguageRead{{ count }}">
                      <mat-option *ngFor="let boo of boolean" [value]="boo">
                        {{ boo }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-3">
                  <mat-form-field>
                    <mat-label>Write</mat-label>
                    <mat-select placeholder="Read" formControlName="motherLanguageWrite{{ count }}">
                      <mat-option *ngFor="let boo of boolean" [value]="boo">
                        {{ boo }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-3">
                  <mat-form-field>
                    <mat-label>Speak</mat-label>
                    <mat-select placeholder="Read" formControlName="motherLanguageSpeak{{ count }}">
                      <mat-option *ngFor="let boo of boolean" [value]="boo">
                        {{ boo }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <!-- Guardian -->
              <h1>3. Guardian</h1>
              <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-12">
                  <mat-form-field appearance="fill">
                    <mat-label>Guardian Name</mat-label>
                    <input matInput placeholder="Guardian Name" formControlName="guardianName" />
                    <mat-error *ngIf="
                        updateStudentForm
                          .get('guardianName')
                          .hasError('required')
                      ">Guardian name is required
                    </mat-error>
                    <mat-error *ngIf="
                        updateStudentForm
                          .get('guardianName')
                          .hasError('maxlength')
                      ">You can only enter maximum of 50 character
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-12">
                  <!-- Contact -->
                  <mat-form-field appearance="fill">
                    <mat-label>Guardian Contact</mat-label>
                    <input type="number" matInput placeholder="Guardian Contact" formControlName="guardianContact"
                      (blur)="checkParentValidation('guardian')" />
                  </mat-form-field>
                  <span class="text-danger" *ngIf="parentNumberExist[2]">
                    Mobile number is already in use.
                  </span>
                </div>

                <div class="col-md-3 col-sm-3 col-xs-12">
                  <!-- Email -->
                  <mat-form-field appearance="fill">
                    <mat-label>Guardian Email</mat-label>
                    <input type="email" matInput placeholder="Guardian Email" formControlName="guardianEmail" />
                    <mat-error *ngIf="
                        updateStudentForm
                          .get('guardianEmail')
                          .hasError('pattern')
                      ">Invalid email format</mat-error>
                  </mat-form-field>
                </div>
                <!-- Qualification -->
                <div class="col-md-3 col-sm-3 col-xs-12">
                  <!-- Qualification -->
                  <mat-form-field appearance="fill">
                    <mat-label>Guardian Qualification</mat-label>
                    <!-- <input matInput placeholder="Guardian Name" formControlName="guardianQualification" class="form-control"> -->
                    <mat-select placeholder="Guardian Qualification" formControlName="guardianQualification">
                      <mat-option *ngFor="let qualification of parentQualification" [value]="qualification">
                        {{ qualification }}
                      </mat-option>
                    </mat-select>
                    <!-- <mat-error *ngIf="studentForm.get('guardianQualification').hasError('required')"> required </mat-error> -->
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <!-- Occupation -->
                <div class="col-md-12">
                  <mat-form-field appearance="fill">
                    <mat-label>Occupation</mat-label>
                    <input type="tel" matInput placeholder="Occupation" formControlName="g_occupation" />
                  </mat-form-field>
                </div>
                <!-- Aadhar -->
                <div class="col-md-12">
                  <mat-form-field appearance="fill">
                    <mat-label>Aadhaar Card no</mat-label>
                    <input type="tel" matInput placeholder="Aadhaar Card no" formControlName="guardianaadhaarNo" />
                    <mat-error *ngIf="
                        updateStudentForm
                          .get('guardianaadhaarNo')
                          .hasError('maxlength')
                      ">you have reached maximum limit of characters
                    </mat-error>
                    <mat-error *ngIf="
                        updateStudentForm
                          .get('guardianaadhaarNo')
                          .hasError('pattern')
                      ">
                      aadhaarNo can only have numbers
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <!-- Language Proficiency -->
              <h1>Language Proficiency</h1>
              <div class="row" *ngFor="let count of countOfFour">
                <div class="col-md-3 col-sm-3 col-xs-3">
                  <mat-form-field appearance="fill">
                    <mat-label>Language {{ count }}</mat-label>
                    <input matInput placeholder="Language {{ count }}" formControlName="guardianLanguage{{ count }}" />
                  </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-3">
                  <mat-form-field>
                    <mat-label>Read</mat-label>
                    <mat-select placeholder="Read" formControlName="guardianLanguageRead{{ count }}">
                      <mat-option *ngFor="let boo of boolean" [value]="boo">
                        {{ boo }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-3">
                  <mat-form-field>
                    <mat-label>Write</mat-label>
                    <mat-select placeholder="Read" formControlName="guardianLanguageWrite{{ count }}">
                      <mat-option *ngFor="let boo of boolean" [value]="boo">
                        {{ boo }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-3">
                  <mat-form-field>
                    <mat-label>Speak</mat-label>
                    <mat-select placeholder="Read" formControlName="guardianLanguageSpeak{{ count }}">
                      <mat-option *ngFor="let boo of boolean" [value]="boo">
                        {{ boo }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>


              <!-- <div class="import-btn">
                    <button class="btn btn-primary" (click)="createStudent()" [disabled]="!studentForm.valid">Submit</button>
                </div> -->
            </mat-expansion-panel>
          </mat-accordion>
          <div style="margin: 20px;">
            <form #update="ngForm" (ngSubmit)="updateStudent()">
              <div class="form-group import-btn">
                <button class="btn btn-primary" [disabled]="!updateStudentForm.valid">
                  Update Student
                </button>
                <input type="reset" id="reset" class="btn btn-primary" value="Reset" hidden />
              </div>
            </form>
          </div>

        </form>

      </div>
    </div>
  </div>
</div>