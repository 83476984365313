<div>
  <h3>Student Requests</h3>
  <br />
  <div class="user-signup">
    Activate User Signup
    <!-- <mat-slide-toggle [checked]="userActive" (change)="userSignup()">{{userActive ? 'Deactivate' : 'Activate'}}
    </mat-slide-toggle> -->
    <button class="btn" (click)="userSignup()">{{userActive ? 'Deactivate' : 'Activate'}}</button>
  </div>
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource">

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <!-- Phone Column -->
      <ng-container matColumnDef="phoneno">
        <th mat-header-cell *matHeaderCellDef> Phone Number </th>
        <td mat-cell *matCellDef="let element"> {{element.username}} </td>
      </ng-container>

      <!-- Class Column -->
      <ng-container matColumnDef="class">
        <th mat-header-cell *matHeaderCellDef> Class </th>
        <td mat-cell *matCellDef="let element"> {{element.class?.name ? element.class?.name : '-'}} </td>
      </ng-container>

      <!-- Section Column -->
      <ng-container matColumnDef="section">
        <th mat-header-cell *matHeaderCellDef> Section </th>
        <td mat-cell *matCellDef="let element"> {{element.section?.name ? element.section?.name : '-'}} </td>
      </ng-container>

      <!-- Aprrove Column -->
      <ng-container matColumnDef="approve" style="width: 10%;">
        <th mat-header-cell *matHeaderCellDef> Approve </th>
        <td mat-cell *matCellDef="let element"> <button mat-mini-fab color="primary" (click)="approve(element._id)">
            <mat-icon>done</mat-icon>
          </button></td>
      </ng-container>

      <!-- Cancel Column -->
      <ng-container matColumnDef="reject">
        <th mat-header-cell *matHeaderCellDef> Reject </th>
        <td mat-cell *matCellDef="let element"> <button mat-mini-fab color="primary" (click)="reject(element._id)">
            <mat-icon>close</mat-icon>
          </button></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageIndex]="0" [pageSize]="pageSize" showFirstLastButtons #paginator
      aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
</div>