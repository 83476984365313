<div>
  <div class="header">
    <h3>Report of {{schoolName}} - {{percentage}}</h3>
  </div>
  <div class="row">
    <mat-card>
      <mat-card-content>
        <span>{{this.monthData.TotalAssignments}}</span>
        Total Assignments
      </mat-card-content>

    </mat-card>
    <mat-card>
      <mat-card-content>
        <span>{{this.monthData.TotalSubmitted}}</span>
        Total Submitted
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-content>
        <span>{{this.monthData.Evaluated}}</span>
        Evaluated
      </mat-card-content>
    </mat-card>

  </div>
  <div style="display:flex;flex-direction: row;align-items: center;">
    <mat-form-field class="filter" style="width: 70%;margin: 15px;">
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Choose a date</mat-label>
      <input matInput [matDatepicker]="picker" [(ngModel)]="date" (dateInput)="dateFilter($event.target.value)">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <button mat-flat-button color="primary" style="color: #000;height: 7%;margin:0px 15px;margin-bottom:20px"
      (click)="ExportTOExcel()">Export</button>
  </div>

</div>


<div class="mat-elevation-z8" #TABLE>
  <table mat-table [dataSource]="dataSource">

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.className + element.sectionName }} </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> Status </th>
      <td mat-cell *matCellDef="let element">
        <button class="status" *ngIf="element.status" mat-button style="background-color: #009879;color: white;">
          Created
        </button>
        <button class="status" *ngIf="!element.status" mat-button style="background-color: #cb1939;color: white">
          Not Created
        </button>
      </td>
    </ng-container>

    <!-- No of Students Column -->
    <ng-container matColumnDef="totalassignment">
      <th mat-header-cell *matHeaderCellDef> No. of Assignments </th>
      <td mat-cell *matCellDef="let element">
        {{ element.status ? element.totalAssignments : 0}}
      </td>
    </ng-container>

    <!-- Percentage Column -->
    <ng-container matColumnDef="submitted">
      <th mat-header-cell *matHeaderCellDef> Submitted Assignments </th>
      <td mat-cell *matCellDef="let element">
        {{ element.status ? element.submitted : 0 }}
      </td>
    </ng-container>

    <!-- Marked At Column -->
    <ng-container matColumnDef="evaluated">
      <th mat-header-cell *matHeaderCellDef>Evaluated Assignments </th>
      <td mat-cell *matCellDef="let element">
        {{ element.status ? element.evaluated : 0 }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20, 100]" showFirstLastButtons>
  </mat-paginator>

</div>