<div class="classcontainer">
    <div class="modal-header">
        <h4 class="modal-title">Update Profile</h4>
        <span>
            <h5 class="modal-title">Role name: {{rolename}}</h5>
        </span>
    </div>
    <div class="modal-body px-0" *ngIf="isLoaded">
        <!-- <div style="overflow-y: hidden; height: calc(100vh - 15rem);">
            <div class="px-2" style="overflow-y: auto; height: 100%;"> -->
        <form [formGroup]="userForm" autocomplete="off">

            <!-- Name -->

            <div class="row">
                <div class="col-md-4 col-sm-4 col-xs-12">
                    <mat-form-field>
                        <mat-label>Name</mat-label>
                        <input matInput placeholder="Name" formControlName="name" class="form-control"
                            #firstname="matInput" required>
                        <mat-error *ngIf="userForm.get('name').hasError('required')">User name is required </mat-error>
                        <mat-error *ngIf="userForm.get('name').hasError('maxlength')">You can only enter maximum
                            of 50 character </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-12">
                    <!-- Contact -->
                    <mat-form-field>
                        <mat-label>Contact Number</mat-label>
                        <input type="number" matInput placeholder="Contact Number" formControlName="mobile"
                            class="form-control" [readonly]="readonly">

                    </mat-form-field>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-12">
                    <!-- Password -->
                    <mat-form-field>
                        <mat-label>Password</mat-label>
                        <input type="text" matInput placeholder="Password" formControlName="password"
                            class="form-control" required>
                        <mat-error *ngIf="userForm.get('password').hasError('required')"> password is required
                        </mat-error>
                    </mat-form-field>
                </div>
                <!-- <div class="col-md-4 col-sm-4 col-xs-12">
                <mat-form-field>
                        <mat-label>Branch</mat-label>
                        <mat-select placeholder="Branch" formControlName="branch_id" required>
                            <mat-option *ngFor="let branch of branches" [value]="branch.name">
                                {{branch.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field> -->
                <!-- </div> -->
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-4 col-xs-12">
                    <!-- Gender -->


                    <mat-radio-group formControlName="gender" color="primary">
                        <mat-label>Gender*</mat-label>
                        <mat-radio-button class="example-radio-button" *ngFor="let gend of gender" [value]="gend">
                            {{gend}}
                        </mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="userForm.get('gender').hasError('required')"> gender is required </mat-error>

                </div>
                <div class="col-md-4 col-sm-4 col-xs-12">
                    <!-- DOB -->

                    <mat-form-field>
                        <mat-label>Date of Birth (Please select from calender)</mat-label>
                        <input matInput [matDatepicker]="picker2" formControlName="dob">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>

                    </mat-form-field>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-12">
                    <!-- Email -->
                    <mat-form-field>
                        <mat-label>Email</mat-label>
                        <input type="email" matInput placeholder="Email" formControlName="email">
                        <mat-error *ngIf="userForm.get('email').hasError('pattern')"> Invalid email format
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">

                <!-- Address -->
                <mat-form-field appearance="fill">
                    <mat-label>Address</mat-label>
                    <textarea matInput placeholder="Address" formControlName="address"></textarea>
                    <mat-error *ngIf="userForm.get('address').hasError('maxlength')">You can only enter maximum
                        of 150 character </mat-error>
                </mat-form-field>

            </div>

            <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-12">
                    <!-- Country -->
                    <!-- <mat-form-field> -->
                        <mat-label>Select Country</mat-label>
                        <ng-select [clearable]="false" placeholder="Country" formControlName="country" (change)="getStates()">
                            <ng-option *ngFor="let country of countries" [value]="country._id">
                                {{country.country_name}}
                            </ng-option>
                        </ng-select>
                    <!-- </mat-form-field> -->
                </div>
                <div class="col-md-3 col-sm-3 col-xs-12">
                    <!-- State -->
                    <!-- <mat-form-field> -->
                        <mat-label>Select State</mat-label>
                        <ng-select [clearable]="false" placeholder="State" formControlName="state" (change)="getCities()">
                            <ng-option *ngFor="let state of states" [value]="state._id">
                                {{state.state_name}}
                            </ng-option>
                        </ng-select>
                    <!-- </mat-form-field> -->
                </div>
                <div class="col-md-3 col-sm-3 col-xs-12">
                    <!-- City -->
                    <!-- <mat-form-field> -->
                        <mat-label>Select City</mat-label>
                        <ng-select [clearable]="false" placeholder="City" formControlName="city">
                            <ng-option *ngFor="let city of cities" [value]="city._id">
                                {{city.city_name}}
                            </ng-option>
                        </ng-select>
                    <!-- </mat-form-field> -->

                </div>
                <div class="col-md-3 col-sm-3 col-xs-12">
                    <mat-form-field>
                        <mat-label>Pincode</mat-label>
                        <input matInput type="tel" placeholder="pincode" formControlName="pincode" class="form-control">
                        <mat-error *ngIf="userForm.get('pincode').hasError('maxlength')">You can only enter maximum
                            of 10 number </mat-error>

                        <mat-error *ngIf="userForm.get('pincode').hasError('pattern')"> pincode can only have numbers
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-12">
                    <!-- Aadhar card no -->
                    <mat-form-field>
                        <mat-label>Aadhaar Card no</mat-label>
                        <input type="tel" matInput placeholder="Aadhaar Card no" formControlName="aadhar_card"
                            class="form-control">
                        <mat-error *ngIf="userForm.get('aadhar_card').hasError('maxlength')">you have reached maximum
                            limit of
                            characters </mat-error>
                        <mat-error *ngIf="userForm.get('aadhar_card').hasError('pattern')"> aadhaarNo can only have
                            numbers
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-12">
                    <!-- Blood group -->
                    <mat-form-field>
                        <mat-label>Blood Group</mat-label>
                        <input matInput placeholder="Blood Group" formControlName="blood_gr" class="form-control">
                    </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-12">
                    <!-- Mother Tongue -->
                    <mat-form-field>
                        <mat-label>Mother Tongue</mat-label>
                        <input matInput placeholder="Mother Tongue" formControlName="mother_tounge"
                            class="form-control">
                    </mat-form-field>
                </div>
                <div class="col-md-3 col-sm-3 col-xs-12">
                    <!-- Marital status -->
                    <!-- <mat-form-field> -->
                        <mat-label>Marital status</mat-label>
                        <ng-select [clearable]="false" placeholder="Marital status" formControlName="marital_status">
                            <ng-option *ngFor="let mode of maritalStatuses" [value]="mode">
                                {{mode}}
                            </ng-option>
                        </ng-select>
                    <!-- </mat-form-field> -->
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-4 col-xs-12">
                    <!-- Religion -->
                    <mat-form-field>
                        <mat-label>Religion</mat-label>
                        <input matInput placeholder="Religion" formControlName="religion" class="form-control">
                    </mat-form-field>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-12">
                    <!-- Cast -->
                    <mat-form-field>
                        <mat-label>Caste</mat-label>
                        <input matInput placeholder="Cast" formControlName="caste" class="form-control">
                    </mat-form-field>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-12"></div>
            </div>
            <div class="import-btn">
                <button [disabled]="!userForm.valid" (click)="updateUser()" class="btn btn-primary">Submit</button>
            </div>
        </form>
        <!-- </div>
        </div> -->
    </div>
</div>
