<div class="bodyy">
  <div class="container">
    <div class="row">
      <div class="col-md-12 heading">
        <span>
          <h4>Map Syllabus to you Classes </h4>
        </span>
      </div>
    </div>
  </div>
  <div>
    <table mat-table class="tbl" [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="class">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> class </th>
      
          <td  mat-cell *matCellDef="let row">{{row.className}} </td>
   
        
      </ng-container>
  
      <ng-container matColumnDef="subject" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Subject</th>
        <td mat-cell *matCellDef="let row; let index = index">
          <div style="width: 80%; margin:30px;">
          <ng-container  *ngFor="let sub of subjects ">
             
                <input type="checkbox" id="option-{{row}}" required [checked]="subjectData[row.className] | subjectPipe:sub.name" 
              (click)="setCheckbox(index,sub.name,$event)"> <span>&nbsp;{{sub.name}} &nbsp;&nbsp;&nbsp;</span>
             
              
              
           
           
          </ng-container> </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div class="row">
      <div class="col-md-12">
        <button class="btn add-btn" [disabled]="subjectAddedFlag" (click)="addSubject()">
          <span *ngIf="updateFlag">Update Subject</span>
          <span *ngIf="!updateFlag">Add Subject</span>
        </button>
      </div>
    </div>
  </div>
  </div>
  