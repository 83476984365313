<h3>Mapping</h3>
<div class="row">
  <div class="class" *ngFor="let class of classes">
    <h4>{{class.className}}</h4>
    <h5>Syllabus : {{class.syllabusName}}</h5>
    <h5>Board : {{class.boardName}}</h5>
    <h5>Subjects: </h5>
    <div class="subject-container">
      <span *ngFor="let subject of class.subjectList" class="subject">{{subject.name}} &nbsp;</span>
    </div>
  </div>
</div>