<!--Head part-->
<div class="row">
  <div class="col-md-9 heading">
    <span class="stepForm-title"><b>HI John , Welcome</b></span>
    <p class="stepForm-content">Follow a few simple steps to complete first time configuration</p>
  </div>
  <div class="col-md-3">
    <!-- <div class="stepForm-button">
      <button mat-button matStepperPrevious class="stepForm-previous">Prev</button>|<button mat-button matStepperNext class="stepForm-next-active">Next</button>
    </div> -->
  </div>
</div>
<!--Body part-->
<div class="row">
  <!--Side detail -->
  <div class="col-md-3 text-right">
    <!--step-1 content-->
    <div>
      <span class="side-title-1 "><b>Update School Data</b></span>
      <p>Add any missing data or change existing data</p>
    </div>
    <!--step-2 content-->
    <div class="step-two">
      <span class="side-title-2"><b>Add Classes</b></span>
      <p>It is required for you to map the classes to its specific Boards</p>
    </div>
    <!--step-3 content-->
    <div class="step-three">
      <span class="side-title-3"><b>Add Subjects</b></span>
      <p>Map Subjects to Clasess</p>
    </div>
  </div>
  <!--Step Form-->
  <div class="col-md-9">
    <mat-vertical-stepper [linear]="true" #stepper labelPosition="left">
      <mat-step [stepControl]="schoolForm">
        <ng-template matStepLabel>Update School Data</ng-template>
        <form [formGroup]="schoolForm">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Institution Name*</label>
                <input name="name" class="form-control" placeholder="Institution Name" formControlName="institution" required>
                <!-- <div class="invalid-feedback" *ngIf="schoolForm.get('institution').errors?.required ">
                  <span>Required</span>
                </div> -->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Address*</label>
                <input class="form-control" name="name" placeholder="Address" formControlName="address" required>
                <!-- <div class="invalid-feedback" *ngIf="schoolForm.get('address').errors?.required ">
                  <span>Required</span>
                </div> -->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label>City*</label>
                <select name="city" class="form-control" placeholder="City" formControlName="city" required>
                  <option>Select</option>
                  <option *ngFor="let city of cities" [value]="city.city_name">{{city.city_name}}</option>
                </select>
                <!-- <div class="text-danger" *ngIf=" schoolForm.get('city').errors?.required ">
                  <span>Required</span>
                </div> -->
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>States*</label>
                <select name="state" class="form-control" placeholder="State" formControlName="state" required>
                  <option>Select</option>
                  <option *ngFor="let state of states" [value]="state.state_name">{{state.state_name}}</option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Country*</label>
                <select name="country" class="form-control" placeholder="Country" formControlName="country" required>
                  <option>Select</option>
                  <option *ngFor="let country of countries" [value]="country.country_name"> {{country.country_name}}</option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Pincode*</label>
                <input type="number" placeholder="pincode" formControlName="pinCode" class="form-control" required>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label>Institution email ID*</label>
                <input type="email" placeholder="Institution email" formControlName="email" class="form-control" required>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Contact Number*</label>
                <input type="number" placeholder="Contact Number" formControlName="contact" class="form-control">
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <ng-container *ngIf="boards.length>0">
                  <label>Board*</label>
                  <select name="board" placeholder="Select Board" formControlName="board" class="form-control" required>
                    <option>Select</option>
                    <option *ngFor="let boar of boards" [value]="boar._id">{{boar.name}}</option>
                  </select>
                </ng-container>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <ng-container *ngIf="schoolType.length>0">
                  <label>Type of Institution*</label>
                  <select name="typeInstitution" placeholder="Select Institution" formControlName="schoolType" class="form-control" required>
                    <option>Select</option>
                    <option *ngFor="let type of schoolType" [value]="type._id">{{type.stype}}</option>
                  </select>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label>Institution Website*</label>
                <input name="website" placeholder="Institution Website" formControlName="website" class="form-control">
              </div>
            </div>
          </div>
          <!--Branch-->
          <div class="row">
            <div class="col-md-3">
              <span><b>Add Branches</b></span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-12">
              <!-- Number of branches -->
              <label>Number of Branches*</label>
              <input placeholder="No of branches" class="form-control" formControlName="noOfBranches" (keyup)="addBranchContainer()" required>
            </div>
          </div>

          <div formArrayName="branch" *ngFor="let branc of branchContainer; let i = index">
            <div [formGroupName]="i">
              <hr *ngIf="i > 0">
              <!-- <div>
                <h4>Enter branch {{i+1}} details</h4>
              </div> -->
              <div class="row">
                <div class="col-md-4 col-sm-4 col-xs-12">
                  <div class="form-group">
                    <label [attr.for]="'branchName'+i">Branch {{i+1}} Name*</label>
                    <input type="text" placeholder="Branch Name" [id]="'branchName'" name="branchName" type="text" class="form-control" formControlName="branchName" required>
                  </div>
                </div>
                <div class="col-md-4 col-sm-4 col-xs-12">
                  <div class="form-group">
                    <label [attr.for]="'branchContact'+i">Branch {{i+1}} Contact*</label>
                    <input type="number" class="form-control" [id]="'branchContact'" name="branchContact" placeholder="Branch Contact" formControlName="branchContact" required>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label [attr.for]="'branchAddress'+i">Branch {{i+1}} Address*
                    </label>
                    <input type="text" class="form-control" [id]="'branchAddress'" name="branchAddress" placeholder="Branch Address" formControlName="branchAddress">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <button mat-button matStepperNext (click)="saveSchoolForm()">Next</button>
          </div>
        </form>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Add Classes</ng-template>
        <div class="mat-elevation-z8">
          <div class="row">
            <div class="col-md-4 col-sm-4 col-xs-12 ">

              <table mat-table [dataSource]="dataSource" matSort>
                <!-- Checkbox Column -->
                <ng-container matColumnDef="select">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
                  <td mat-cell *matCellDef="let row">
                    <form #form="ngForm">

                      <!-- <div *ngFor="let li of listedValue; index as i" class="option-group"> -->
                      <input type="checkbox" id="option-{{row.className}}" [value]="l" required (click)="setCheckbox(row)">
                      <!-- </div> -->



                    </form>
                  </td>
                </ng-container>
                <!--  name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Available classes/grades </th>
                  <td mat-cell *matCellDef="let row"> {{row.className}} </td>
                </ng-container>



                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


              </table>
              <!-- <div class="import-btn">
                <button class="btn btn-primary" (click)="saveOptions();">Submit</button>
              </div> -->


            </div>
            <div class="col-md-8 col-sm-8 col-xs-12 " *ngIf="isClassSelected" [formGroup]="boardForm">
              <table mat-table [dataSource]="dataSource2" matSort formArrayName="classMap">


                <ng-container matColumnDef="class">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> class </th>
                  <td mat-cell *matCellDef="let row">{{row.className}} </td>
                </ng-container>
                {{dataSource2}}
                <ng-container matColumnDef="board">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Board</th>
                  <td mat-cell *matCellDef="let index = index" [formGroupName]="index">
                    <select name="board" class="form-control" formControlName="board" (change)="mapBoardWithClass(index,$event.target.value)" placeholder="-- select an option --">
                      <option>Select</option>
                      <option *ngFor="let board of boards; let i =index" [value]="board.name">
                        {{board.name}}
                      </option>
                    </select>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
              </table>
            </div>
          </div>
          <div>
            <button mat-button matStepperPrevious>Back</button>
            <button mat-button matStepperNext (click)="saveOptions2()">Next</button>
          </div>
        </div>
        <!-- <div>
            <button mat-button matStepperPrevious>Back</button>
            <button mat-button matStepperNext>Next</button>
          </div> -->
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>class mapping</ng-template>
        <!-- *ngFor="let a of optionsCont ; let i = index" -->
        <div>
          <div class="classcontainer" *ngFor="let option of boardForm.get('classMap').value ; let i = index">
            <div class="container">
              <div class="row">
                <div class="col-md-3">
                  <span>
                    <b>
                      <h3>{{option.class}}</h3>
                    </b>
                  </span>
                </div>
                <div class="col-md-9">
                  <span>
                    <h3>{{option.board}}</h3>
                  </span>
                </div>
              </div>
              <br>
              <!-- show added syllabus -->
              <ng-container *ngIf="isSyllabusFlag">
                <div class="row">
                  <div class="col-md-12">
                    <span>Syllabus - {{option.sylabus}}</span>
                  </div>
                </div>
              </ng-container>
              <br>
              <ng-container *ngIf="isSubjectFlag">
                <div class="row">
                  <div class="col-md-12">
                    <span>Subject - {{option.subject}}</span>
                  </div>
                </div>
              </ng-container>
              <br>
              <div class="row">
                <!-- If no Syllabus added -->
                <ng-container>
                  <div class="col-md-4">
                    <button mat-raised-button color="accent" (click)="openEditSylabus()">
                      <span *ngIf="!isSyllabusFlag">Add Syllabus</span>
                      <span *ngIf="isSyllabusFlag">Update Syllabus</span>
                    </button>
                  </div>
                </ng-container>
                <!-- if no subject added -->
                <!-- *ngIf="!isSubjectFlag" -->
                <ng-container>
                  <div class="col-md-4">
                    <button [disabled]="subjectButtonDisable" mat-raised-button color="accent" (click)="openEditSubject()">
                      <span *ngIf="!isSubjectFlag">Add Subject</span>
                      <span *ngIf="isSubjectFlag">Update Subject</span>
                    </button>
                  </div>
                </ng-container>
                <!-- show added subject -->
              </div>
            </div>
            <br><br>
          </div>
          <hr>
        </div>
        <div>
          <button mat-button matStepperPrevious>Back</button>
          <button class="btn" matStepperNext [disabled]="stepFormValidationFlag" (click)="addStepForm()">Done</button>
          <!-- <button mat-button (click)="stepper.reset()">Reset</button> -->
        </div>

        <!-- <div>
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button (click)="stepper.reset()">Reset</button>
        </div> -->
      </mat-step>
    </mat-vertical-stepper>

  </div>
</div>
